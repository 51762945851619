import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  MYASSETS_DRIVERS_OFCUSTOMER,
  MYASSETS_FLEETS_OFCUSTOMER,
  MYASSETS_TEAMS_OFCUSTOMER,
  MYASSETS_VEHICLES_OFCUSTOMER,
  CUSTOMERS_BY_CUSTOMER_ENDPOINT,
  TRAILERS_BY_CUSTOMER_ENDPOINT
} from 'src/app/core/config/config.constants';
import { DataService } from 'src/app/core/services/data.service';
import * as _ from 'lodash';
import { FilterOptions } from 'src/app/core/models/filterOptions';


@Injectable({
  providedIn: 'root'
})
export class FilterRefService {
  constructor(private http: HttpClient,
              private dataService: DataService) {

  }

  baseApi = environment.baseApi;

  //selectedCustomer = this.getSelectedCustomer()?.uid;
  filterRefChangeEvent = new EventEmitter<FilterOptions | null>();

  getFleetsOfCustomers$(uidCustomer): Observable<any> {
    return this.http
      .get<any>(
        this.baseApi + MYASSETS_FLEETS_OFCUSTOMER + uidCustomer
      )
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          // this.setSessionFleetsOfCustomers$(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  getDriversOfCustomers$(uidCustomer): Observable<any> {
    return this.http
      .get<any>(
        this.baseApi + MYASSETS_DRIVERS_OFCUSTOMER + uidCustomer
      )
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          //console.log('Payload',Payload)

          // sort by fullName
          Payload.sort((a, b) => {
              const fullNameA = a.fullName.toUpperCase(); // ignore upper and lowercase
              const fullNameB = b.fullName.toUpperCase(); // ignore upper and lowercase
              if (fullNameA < fullNameB) {
                return -1;
              }
              if (fullNameA > fullNameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });


          // this.setSessionDriversOfCustomers$(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  getTeamsOfCustomers$(uidCustomer): Observable<any> {
    return this.http
      .get<any>(
        this.baseApi + MYASSETS_TEAMS_OFCUSTOMER + uidCustomer
      )
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          // this.setSessionTeamsOfCustomers$(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  getVehiclesOfCustomers$(uidCustomer ): Observable<any> {
    return this.http
      .get<any>(
        this.baseApi + MYASSETS_VEHICLES_OFCUSTOMER + uidCustomer
      )
      .pipe(
        map((Payload) => {
         // console.log(Payload)
           //sort by plate
           Payload.sort((a, b) => {
            const plateA = a.plate.toUpperCase(); // ignore upper and lowercase
            const plateB = b.plate.toUpperCase(); // ignore upper and lowercase
            if (plateA < plateB) {
              return -1;
            }
            if (plateA > plateB) {
              return 1;
            }

            // plate must be equal
            return 0;
          });

          // this.setSessionVehiclesOfCustomers$(Payload);
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  getTrailersOfCustomers$(uidCustomer): Observable<any> {
    return this.http
      .get<any>(
        this.baseApi + TRAILERS_BY_CUSTOMER_ENDPOINT + "/" + uidCustomer
      )
      .pipe(
        map((Payload) => {
          //sort by plate
          Payload.sort((a, b) => {
            const plateA = a.plate.toUpperCase(); // ignore upper and lowercase
            const plateB = b.plate.toUpperCase(); // ignore upper and lowercase
            if (plateA < plateB) {
              return -1;
            }
            if (plateA > plateB) {
              return 1;
            }

            // plate must be equal
            return 0;
          });
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }



  getCustomers$(): Observable<any> {
    let userInfo = this.dataService.getCurrentUserInfo();
    return this.http
      .get<any>(
        this.baseApi +
          CUSTOMERS_BY_CUSTOMER_ENDPOINT +
          '/' +
          userInfo.customer.uid
      )
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          this.updateCustomerListWithChildrenInfo(Payload);
          // this.setSessionCustomers$(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }



  updateCustomerListWithChildrenInfo(customerList: any) {
    let self = this;

    for (let customer of customerList) {
      var childrens;

      // Not root
      if (customer.uidParent !== null) {
        var customerParent:any = _.find(customerList, { uid: customer.uidParent });
        
        // Controlla se customerParent è definito
        if (customerParent) {
            var childrens:any = _.filter(customerList, { uidParent: customer.uidParent });
            
            // Assicurati che customerParent sia un oggetto con le proprietà necessarie
            if (typeof customerParent === 'object' && customerParent !== null) {
                customerParent['nameWithChildren'] = customerParent.name + ' (' + childrens.length + ')';
            }
        }
    }
     else {
        // Root customer
        childrens = _.filter(customerList, { uidParent: customer.uid });
        customer.nameWithChildren =
          customer.name + ' (' + childrens.length + ')';
      }
      if (!customer.nameWithChildren) customer.nameWithChildren = customer.name;
    }
    return customerList;
  }



  // private setSessionCustomers$(dataIn) {
  //   sessionStorage.setItem('Customers$', JSON.stringify(dataIn));
  // }

  // getSessionCustomers$() {
  //   return JSON.parse(sessionStorage.getItem('Customers$')|| '0');
  // }

  // private setSessionVehiclesOfCustomers$(dataIn) {
  //   sessionStorage.setItem('VehiclesOfCustomers$', JSON.stringify(dataIn));
  // }

  // getSessionVehiclesOfCustomers$() {
  //   return JSON.parse(sessionStorage.getItem('VehiclesOfCustomers$') || '0');
  // }

  // private setSessionDriversOfCustomers$(dataIn) {
  //   sessionStorage.setItem('DriversOfCustomers$', JSON.stringify(dataIn));
  // }

  // getSessionDriversOfCustomers$() {
  //   return JSON.parse(sessionStorage.getItem('VehiclesOfCustomers$') || '0');
  // }

  // private setSessionFleetsOfCustomers$(dataIn) {
  //   sessionStorage.setItem('FleetsOfCustomers$', JSON.stringify(dataIn));
  // }

  // getSessionFleetsOfCustomers$() {
  //   return JSON.parse(sessionStorage.getItem('FleetsOfCustomers$') || '0');
  // }

  // private setSessionTeamsOfCustomers$(dataIn) {
  //   sessionStorage.setItem('TeamsOfCustomers$', JSON.stringify(dataIn));
  // }

  // getSessionTeamsOfCustomers$() {
  //   return JSON.parse(sessionStorage.getItem('TeamsOfCustomers$') || '0');
  // }

  //gestione eventi del filtro

  // initFilterRef(
  //   options: FilterOptions | null,
  //   isRefreshed?: boolean,
  //   firstTabOption?: string,
  //   timespanSel?: string
  // ){
  //   this.filterRefChangeEvent.emit(options);
  // }






}
