import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CurrentUserInfo } from '../models/currentUserInfo';
import { PossiblePreferences } from '../models/possiblePreferences';
/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-unused-vars */
import * as locale from 'locale-codes'
import { AppStateInterface } from 'src/app/appState.interface';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class DataService {
  private currentUserInfo: CurrentUserInfo;
  private possiblePreferences: PossiblePreferences;
  private includeChildrens: any = false;
  refreshNavEvent: Subject<any> = new Subject<any>();
  private current = new Subject<any>();



  constructor( private translate : TranslateService) {

  }

  setCurrentUserInfo(currentUserInfo: CurrentUserInfo) {
    this.currentUserInfo = currentUserInfo;
    localStorage.setItem('user', JSON.stringify(currentUserInfo));



  }

  setIncludeChildren(bool) {
    this.includeChildrens = bool;
  }

  getIncludeChildren() {
    return this.includeChildrens;
  }

  getCurrentUserInfo() {
    if (this.currentUserInfo) return this.currentUserInfo;
    else return this.getCurrentUser();
  }

  refreshNav() {
    this.refreshNavEvent.next(null);
  }

  getCurrentUser() {
    let result = localStorage.getItem('user');
    return JSON.parse(result || '{}');
  }

  setPossiblePreferences(possiblePreferences: PossiblePreferences) {
    this.possiblePreferences = possiblePreferences;
  }

  getPossiblePreferences() {
    return this.possiblePreferences;
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translate.setDefaultLang(language);
  }

  getLanguage(): string {
    let lang = localStorage.getItem('language');

    if (lang)
    return lang;
      else
    return 'en';
  }

  getCurrent = this.current.asObservable();
  setCurrent(current: CurrentUserInfo) {
    this.current.next(current);
  }

  getLocale(lang,type){
    // FZ 6163 for documentation on localize :
    //  https://www.npmjs.com/package/locale-codes
    let res;
    if (lang == 'en')
      res = 'en-GB'
    else
      res = lang +'-'+ lang.toUpperCase();;

    res = locale.getByTag(res)[type]
    return res;

  }
}
