import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { TACHO_CARD_DATA, TACHO_DAILY_ACTIVITIES_ENDPOINT, TACHO_DAILY_REPORT_EXCEL, TACHO_INFRINGEMENTS_DETAILS, TACHO_INFRINGEMENTS_LETTERS, TACHO_LIST_BY_TEAM } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { SessionService } from 'src/app/core/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TachoService {

    baseApi = '';

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private sessionService: SessionService
    ) {this.baseApi = environment.baseApi; }

    exportDailyByExcel(params): Observable<any> {
        return this.apiService.post(TACHO_DAILY_REPORT_EXCEL + '?cacheBuster=' + this.getCurrentTimeStamp(), params);
    }

    getCurrentTimeStamp() {
        return Date.now();
    }

    getDailyDriverActivities(params): Observable<any> {
        return this.apiService.post(TACHO_DAILY_ACTIVITIES_ENDPOINT + '?cacheBuster=' + this.getCurrentTimeStamp(), params);
    }


    getListByTeamsUid(params): Observable<any> {
        return this.apiService.get(TACHO_LIST_BY_TEAM);
    }

    getDriversActivitiesReports(params): Observable<any> {
        return this.apiService.get(TACHO_CARD_DATA);
    }

    getInfrigmentsReports(params): Observable<any> {
        return this.apiService.post(TACHO_INFRINGEMENTS_DETAILS, params);
    }

    getInfrigmentsLettersContent(params) {
        return this.http.post(this.baseApi + TACHO_INFRINGEMENTS_LETTERS, params, {
          headers: {
            Accept: 'application/octet-stream'
          },
          responseType: 'arraybuffer'
        });
    }

    doExportPDF$(reportName: any): Observable<any> {

      let token = this.sessionService.get('powerbi_token_' + reportName);
      const powerbi_id = this.sessionService.get('powerbi_id_' + reportName);
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }

      let urlreport  = "https://api.powerbi.com/v1.0/myorg/groups/269ac645-f4ae-4c7a-8d30-8b1d6fe73c44&w=2/reports/" + powerbi_id + "/ExportTo";
      //let urlreport = "https://app.powerbi.com/reportEmbed?reportId=" + powerbi_id + '/ExportTo'

      const body = {format:"PDF"};
      return this.http
        .post<any>(urlreport,body,{headers:headerDict})
        .pipe(
          map((Payload) => {
            //console.log(Payload)
            return Payload;
          }),
          catchError((err: any) => {


            return EMPTY;
          })
        );
    }



}
