<div class="card">
    <h3 class="card-header">{{"global.helpDescriptionHead" | translate}}</h3>
    <div class="card-body">
        <div class="row">
            <div class="col-xs-12">
                <p [innerHtml]="data | translate"></p>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <a (click)="close()" class="btn btn-ok">{{"global.modal.ok" | translate}}</a>
    </div>
</div>