import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { faCheck, faDoorOpen,faDoorClosed,faPowerOff  } from '@fortawesome/free-solid-svg-icons';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'actia-tableEditDevExtreme',
  templateUrl: './tableEditDevExtreme.component.html',
  styleUrls: ['./tableEditDevExtreme.component.scss']
})

export class ActiaTableEditDevExtremeComponent implements OnInit  {

    constructor(
      private cdr: ChangeDetectorRef,
      private translate: TranslateService,
      private dataService : DataService,
      public dialog: MatDialog,) {





     }

    // for documentation about component see directly Dev Extreme Table https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/Overview/Angular/Light/
    //  @Input() dataTable:any;
    @Input() dataCols:any;

    @Input()
    get dataTable(): any { return this._dataTable; }
    set dataTable(value: any) {
      this._dataTable = value;
    }
    private _dataTable:any;

    private _selectedRows: any[] =[]
    @Input()
    get selectedRows(): any[] {

        return this._selectedRows
    }
    public set selectedRows(value: any[]) {
     // console.log('selectedRows',value)


        this._selectedRows = value

    }
    //@Input() writeGrant:boolean=false;

    //#FZ NB. se non passo inkeyExpr la tabelle restituisce un array di elementi e non le Key
    // PROPERTIES
    @Input() idComponent:string ='';
    @Input() componentTitle = ''
    @Input() inkeyExpr:any = ''
    @Input() showBorders:boolean=true;
    @Input() allowColumnReordering:boolean=true;
    @Input() allowColumnResizing:boolean=true;
    @Input() rowAlternationEnabled:boolean=true;
    @Input() showRowLines:boolean=true;
    @Input() selectAllMode:string = 'allPages';
    @Input() pagerVisibile:boolean = true;
    @Input() pageSize:number=20;
    @Input() allowedPageSizes=[20, 40, 60];
    @Input() showPageSizeSelector:boolean=true;
    @Input() showSearch:boolean=true;
    @Input() searchWidth:number= 200;
    @Input() displayMode:string='full';
    @Input() showInfo:boolean=true;
    @Input() showNavigationButtons:boolean=true;
    @Input() enabledExportButton:boolean=false;
    @Input() selectMode:string='multiple';

    public writeGrant: boolean = true;
    @Input() showedit:boolean = true;

    //EVENTS
    @Output() onSelectedRows = new EventEmitter()
    @Output() gridComponent = new EventEmitter()
    @Output() onEventRowRemoving = new EventEmitter();
    @Output() onEventRowUpdated = new EventEmitter();
    @Output() onEventSaved = new EventEmitter();
    @Output() onEventEditCanceled = new EventEmitter();
    @Output() onEventEditingStart = new EventEmitter();

    //METODS

    @Input() tableRefresh: any = Observable;

    // gestione eventi da ricevere
    private subscriptions = new Subscription()
    displayFormatDate:string;
    displayFormatDateTime:string;
    userUtc:string;

    faCheck = faCheck;
    faDoorOpen = faDoorOpen;
    faDoorClose = faDoorClosed;
    faPower = faPowerOff;

    @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent | undefined;
    ngOnInit(): void {

      this.translate.setDefaultLang(this.dataService.getLanguage());
      let user = this.dataService.getCurrentUser().user;
      this.userUtc = user.preferences.timezone.description;
      this.translate.get('global.dateFormat').subscribe(dateFormat => {
        this.displayFormatDate = dateFormat;
      });
      this.translate.get('global.dateTimeFormat').subscribe(dateTimeFormat => {
        this.displayFormatDateTime = dateTimeFormat;

      });


      this.subscriptions.add(this.tableRefresh.subscribe(this.refresh()))
     // this.gridComponent.emit(this.grid)
    /// console.log('ngOnInit',this.dataTable)

   }


   ngAfterContentInit(){
     //console.log('ngAfterContentInit',this.dataTable)
     //console.log(this.grid)
     //#FZ espongo l'oggetto completo
     //this.grid?.instance.exportToExcel(true)
     this.gridComponent.emit(this.grid)
    }


    refresh()  {
      console.log('refresh, do something')

      this.grid?.instance.deselectAll();

  }


  ngOnDestroy() {
    // sgancio il componente  da tutti i servizi in observable
    this.subscriptions.unsubscribe();
  }



  eventRowUpdated(e)
  {
    this.onEventRowUpdated.emit(e);
  }

  // eventRowRemoving(e)
  // {
  //   this.onEventRowRemoving.emit(e);
  // }


  eventSaved(e){
    this.onEventSaved.emit(e)

  }

  eventEditCanceled(e)
  {
    this.onEventEditCanceled.emit(e)
  }


  onRowRemoving(e) {

    console.log(e);

    let index = e.component.getRowIndexByKey(e.key);
    let rowEl = e.component.getRowElement(index);
    $(rowEl).addClass("rowToDelete");
    let d = $.Deferred();

    e.cancel = d.promise();

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: "global.confirmDelete",
      position: { top: '40px' }
    });

    dialogRef.afterClosed().subscribe(result => {
      $(rowEl).removeClass("rowToDelete");
      if (!result)
      d.resolve(true)
    else
    {
      d.resolve()

    }
    });

  }


  eventEditingStart(e){
    console.log('edit start')
    this.onEventEditingStart.emit(e);
  }


  eventOnSelectionChanged(e){
    console.log('eventOnSelectionChanged')
    console.log(e)
    this.onSelectedRows.emit(e)
  }


//   rowPrepared(e) {
//     console.log('rowPrepared',e)
//     if (e.rowType === "data") {
//       if (e.data.name === "Fabio") {
//         e.rowElement.style.backgroundColor = "yellow";
//       }
//     }
// }

}




