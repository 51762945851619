import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxTreeViewComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { CUSTOMERS_BY_CUSTOMER_ENDPOINT } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterService } from '../filter/filter.service';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
import { FilterRefService } from 'src/app/core/services/filter-ref.service';
import { AppStateInterface } from 'src/app/appState.interface';
import { Store, select } from '@ngrx/store';
import * as PostActions from '../../../core/store/actions'
import { SelectedCustomerCacheInterface } from 'src/app/core/store/interface/selectedCustomerCache.interface';
import { selectedCustomerSelector } from 'src/app/core/store/selectors';

@Component({
  selector: 'app-cutomers-tree-ref',
  templateUrl: './cutomers-tree-ref.component.html',
  styleUrls: ['./cutomers-tree-ref.component.scss']
})
export class CutomersTreeRefComponent implements OnInit, OnChanges {
  @ViewChild(DxTreeViewComponent, { static: false }) treeViewRef: DxTreeViewComponent;
//  selectedCustomer$: Observable<SelectedCustomerCacheInterface>;

  selectedCustomerFromStore$: any;
  public customers: any;
  @Output() customerSelected = new EventEmitter<any>();
  @Input() refreshCustomers: boolean;
  @Input() selectedCustomer: any;

  treeElementsList: any[] = [];
  @Input() isForFilter: boolean = false;
  subselectedCustomerSelector:Subscription;
  @Input()
  get listIn(): any {
    return this._listIn;
  }
  set listIn(value: any) {

    this._listIn = value;
    //********* IMPORTANT  *************/
    //#FZ this prevent the error on is not extensible
    this.customers = JSON.parse(JSON.stringify(value));
    this.subselectedCustomerSelector = this.store.pipe(select(selectedCustomerSelector)).subscribe((res) => {
     // console.log('selectedCustomerUidSelector',res);
      this.selectedCustomerFromStore$= JSON.parse(JSON.stringify(res));


    });
    if (this.selectedCustomerFromStore$)
    setTimeout(() => {
    this.treeViewRef.instance.expandItem(this.selectedCustomerFromStore$.uid)
  }, 100);
  //   else
  //  {
  //    setTimeout(() => {
  //      this.treeViewRef.instance.expandItem(this.isForFilter ? this.filterService.getSelectedCustomer() ? this.filterService.getSelectedCustomer().uid : this.userInfo.customer.uid : this.userInfo.customer.uid);
  //    }, 100);

  //   }

  }
  private _listIn: any;
  subscription: Subscription;
  userInfo = this.dataService.getCurrentUserInfo();
  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
    public dialog: MatDialog,
   // private filterService: FilterService,
    private dataService: DataService,
    // private filterRefService:FilterRefService,
    // private filterService: FilterService,
    private store:Store<AppStateInterface>) {





     }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.refreshCustomers) {
      this.getCustomers();
    }
  }

  ngOnInit(): void {
   // this.getCustomers();
    //  this.filterService.applyFilterEvent.subscribe(
    //   (data: any) => {
    //      this.filterService.updateSelectedCustomerCache();
    //   }
    //  )
    //  this.subscription = this.filterService.resetCustomer.subscribe(
    //    (selectionEmpty: boolean) => {
    //      this.resetSearchInput();
    //    })
  }

  resetSearchInput() {
    // const searchEditor = this.treeViewRef.searchValue;
    // if (searchEditor) {
    //   setTimeout(() => {
    //     this.treeViewRef.searchValue = '';
    //     this.getCustomers();
    //   }, 1800);
    // }
  }


  getCustomers() {

    // let userInfo = this.dataService.getCurrentUserInfo();
    // this.customers = [];
    // this.apiService.get(CUSTOMERS_BY_CUSTOMER_ENDPOINT + '/' + userInfo.customer.uid)
    //   .subscribe(
    //     (customers: any) => {
    //       this.customers = customers;
    //       this.updateCustomerListWithChildrenInfo(customers);
    //       setTimeout(() => {
    //         this.treeViewRef.instance.expandItem(this.isForFilter ? this.filterService.getSelectedCustomer() ? this.filterService.getSelectedCustomer().uid : userInfo.customer.uid : userInfo.customer.uid);
    //       }, 100);
    //     },
    //     error => {
    //       this.utilsService.showErrorPopup(error);
    //     });


    // this.customers =[];
    // let userInfo = this.dataService.getCurrentUserInfo();
    // this.customers = this.filterRefService.getSessionCustomers$();
    // if (this.customers == '0')
    // {
    //   this.filterRefService.getCustomers$().subscribe(res =>{
    //     this.customers = res;
    //     if (res)
    //       setTimeout(() => {
    //         this.treeViewRef.instance.expandItem(this.isForFilter ? this.filterRefService.getSelectedCustomer() ? this.filterRefService.getSelectedCustomer().uid : userInfo.customer.uid : userInfo.customer.uid);
    //       }, 100);

    //   })
    // }
    // else
    // {
    //   setTimeout(() => {
    //     this.treeViewRef.instance.expandItem(this.isForFilter ? this.filterRefService.getSelectedCustomer() ? this.filterRefService.getSelectedCustomer().uid : userInfo.customer.uid : userInfo.customer.uid);
    //   }, 100);
    // }



  }

  onItemClick(e: any) {
      this.isForFilter = true;
      let treeview = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("dx-treeview-item");
      for (let index = 0; index < treeview.length; index++) {
        treeview.item(index)?.classList.remove('aft-main-bgcolor');
      }
      (e.itemElement as HTMLElement).classList.add('aft-main-bgcolor');

     this.customerSelected.emit(e.itemData);

    //  if (this.isForFilter) {
    //    this.filterService.setSelectedCustomer(e.itemData);
    //    this.filterService.handleCustomerClick(this.treeViewRef);
    // }
  }

  onItemCollapseAndExpanded(e: any) {
    // if (this.isForFilter)
    //   this.filterService.handleTreeExpansionAndCollision(this.treeViewRef);
  }

  onContentReady(e: any) {
    // var selectElement = document.querySelectorAll('.dx-icon');
    // let userInfo = this.dataService.getCurrentUserInfo();
    // for (var i = 0; i < selectElement.length; i++) {
    //   selectElement[i].addEventListener('click', () => {
    //     this.isForFilter = false;
    //     setTimeout(() => {
    //       this.treeViewRef.instance.collapseAll();
    //       this.treeViewRef.instance.expandItem(this.isForFilter ? this.filterService.getSelectedCustomer() ? this.filterService.getSelectedCustomer().uid : userInfo.customer.uid : userInfo.customer.uid);
    //     });
    //   });
    // }
    // e.element.classList.add(this.utilsService.getStylesheetString());
    // if (this.isForFilter) this.filterService.setCustomerTreeItems(this.treeElementsList);
  }

  onItemRendered(e: any) {
    if (this.isForFilter) this.treeElementsList.push(e);
    var span = e.itemElement.getElementsByTagName("span")[0];
    if (e.itemData.isHardLocked === true) {
      span.classList.add('aft-disabled__color');
    }
    else {
      span.classList.remove('aft-disabled__color');
    }
  }

  updateCustomerListWithChildrenInfo(customerList: any) {
  //   let self = this
  //   _.each(self.customers, function (customer) {
  //     var childrens;

  //     // Not root
  //     if (customer.uidParent !== null) {
  //       var customerParent = _.find(customerList, { uid: customer.uidParent });
  //       childrens = _.filter(customerList, { uidParent: customer.uidParent });
  //       customerParent.nameWithChildren = customerParent.name + ' (' + childrens.length + ')';

  //     } else {
  //       // Root customer
  //       childrens = _.filter(customerList, { uidParent: customer.uid });
  //       customer.nameWithChildren = customer.name + ' (' + childrens.length + ')';
  //     }
  //     if (!customer.nameWithChildren) customer.nameWithChildren = customer.name;
  //   });
  }

  ngOnDestroy(): void {
    this.subselectedCustomerSelector.unsubscribe();

}


}
