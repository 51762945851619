<p-dialog header="Header" [(visible)]="visible" [modal]="true" [style]="{ width: '40rem' }" id="my-dialog"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <div class="circle-icon">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <span class="font-bold white-space-nowrap">{{'global.maintenanceInProgress' | translate}}</span>
    </div>
  </ng-template>
  <p class="m-0">
    <!--{{'global.PBIMaintenance' | translate}}-->
    {{mgsTextAlert}}
  </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="visible = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
  </ng-template>
</p-dialog>