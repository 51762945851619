import { HeadersInterceptor } from './core/Interceptors/headers-interceptor.service';
import { CoreModule } from './core/core.module';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { TemplatePageComponent } from './template-page/template-page.component';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './core/Interceptors/token-interceptor.service';
import { initializer } from 'src/keycloak-init';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { WebcallComponent } from './pages/management/device/webcall/webcall.component';
import { HandleErrorInterceptor } from './core/Interceptors/handle-error-interceptor';
import { AuthService } from './core/services/auth.service';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig
} from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService
} from 'keycloak-angular';
import { KeycloakAuthService } from './core/services/keycloak.auth.service';
import { AuthGuard } from './core/guards/keycloak.auth-guard';
import { Router } from '@angular/router';
import { UtilsService } from './core/services/utils.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';


import { PreferenceInterceptor } from './core/Interceptors/preferences-interceptor';
import { DataService } from './core/services/data.service';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PostEffects } from './core/store/effects';
import { HydrationEffects } from './core/store/hydration/hydration.effects';
import { metaReducers } from './core/store/hydration';
import { reducerCompleteApplyState, reducerFilterRefSetting, reducerSetTabAssetChooserRef,  reducerTrailersOfCustomers,  reducersApplySelectedAssets, reducersApplySelectedCustomer, reducersApplySelectedPeriod, reducersCustomers, reducersDriversOfCustomers, reducersFleetsOfCustomers, reducersSelectedCustomerCache,  reducersSetSelectedAssets,  reducersSetSelectedPeriod, reducersSetTabFilterRef, reducersTeamsOfCustomers, reducersUser, reducersVehiclesOfCustomers } from './core/store/reducers';
import { TrackingService } from './pages/tracking/tracking.service';
import { SharedService } from './core/services/tracking-shared.service';






registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeEs);



const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.baseUrl
  },
  palette: {
    popup: {
      background: '#008c95'
    },
    button: {
      background: 'white',
      text: 'gray'
    }
  },
  theme: 'classic',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a  class="cc-link" id="cookieconsent-link" >{{link}}</a>
    </span>
    `
  }
};

@NgModule({
  declarations: [AppComponent, TemplatePageComponent, WebcallComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    KeycloakAngularModule,

    StoreModule.forRoot({}, {}),

    StoreModule.forRoot(
      {
      'getCustomers':reducersCustomers,
      'getVehiclesOfCustomers':reducersVehiclesOfCustomers,
       'getTrailersOfCustomers' : reducerTrailersOfCustomers,
     // 'getFleetsOfCustomers':reducersFleetsOfCustomers,
      'getDriversOfCustomers':reducersDriversOfCustomers,
     // 'getTeamsOfCustomers':reducersTeamsOfCustomers,
      'setSelectedCustomerCache':reducersSelectedCustomerCache,
      'setTabFilterRef':reducersSetTabFilterRef,
      'setTabAssetChooserRef':reducerSetTabAssetChooserRef,
      'user':reducersUser,
      'applySelectedCustomer':reducersApplySelectedCustomer,
      'setSelectedAssets':reducersSetSelectedAssets,
      'applySelectedAssets':reducersApplySelectedAssets,
      'setSelectedPeriod':reducersSetSelectedPeriod,
      'applySelectedPeriod':reducersApplySelectedPeriod,
      'completeApplyState':reducerCompleteApplyState,
      'filterRefSetting':reducerFilterRefSetting
        },
     { metaReducers }),



    EffectsModule.forRoot([PostEffects,HydrationEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
     }),


  ],
  providers: [TrackingService,SharedService,
    /*
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useFactory: function(authService: AuthService) {
          return new HandleErrorInterceptor(authService);
        },
        multi: true,
        deps: [AuthService]
     },*/
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PreferenceInterceptor,
      multi: true,
      deps: [DataService]
    },
    /*     { provide: HTTP_INTERCEPTORS, useClass: HandleErrorInterceptor, multi: true }, */
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, Router, UtilsService]
    },
    KeycloakAuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


