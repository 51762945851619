import { createReducer, on } from '@ngrx/store';

import * as PostsActions from './actions';
import { SelectedCustomerCacheInterface } from './interface/selectedCustomerCache.interface';
import { ListTabRefInterface, TabFilterRefInterface } from './interface/tabFilterRef.interface';
import { CurrentUserInfo } from '../models/currentUserInfo';
import { ICON_STATE_MAPPER } from '../config/config.constants';
import { SelectedAssets } from './interface/selectedAssets.interface';
import { SelectedPeriodFitler } from './interface/selectedPeriod.interface';
import { GeListStateInterface } from './interface/getListState.interface';
import { Certificate } from 'crypto';
import { CompleteApplyState } from './interface/completeApplyState.interface';
import { FilterRefSetting } from './interface/filterRefSetting.interface';
import { act } from '@ngrx/effects';


export const initialState: GeListStateInterface = {
  isLoading: false,
  posts: [],
  error: null
};

export const intialStateCustomerCache: SelectedCustomerCacheInterface = {
  isVirtual: false,
  isFieldTest: false,
  isSubscriptionEnded: false,
  subscriptionEndDate: null,
  vatCode: null,
  fiscalCode: null,
  contactName: null,
  contactPhone1: null,
  contactPhone2: null,
  contactEmail: null,
  contactAddress: null,
  logo: null,
  uidCountry: '',
  uidLegalRule: '',
  groups: [],
  features: [],
  children: [],
  isTachoLight: false,
  maxVehicles: null,
  name: '',
  uidParent: '',
  isHardLocked: false,
  uid: '',
  nameWithChildren: '',
  timezone:null
};

export const initialListStateTabRefFilter : ListTabRefInterface =
{
  isLoading:false,
  defaultAssetType:'VEHIC',
  tabFilterRef:[{
  id:0,
  text:'global.customer',
  enabled:true,
icon:'',
selectionMode:'all'}],

  error:null
}

export const initialSelectedAssets : SelectedAssets =
{
  assetType :'VEHIC',
  selected: [],
  totSelected:0,
  isApply:false

}



export const initialApplySelectedAssets : SelectedAssets =
{
  assetType :'VEHIC',
  selected: [],
  totSelected:0,
  isApply:false
}


export const initialListStateTaAssetChooserRefFilter : ListTabRefInterface = {
isLoading: true,
      tabFilterRef: [
        {
          id: 0,
          text: 'global.vehicles',
          enabled: true,
          icon: 'fa fa-truck',
          selectionMode:'all'
        },
         {
           id: 1,
           text: 'global.driver',
           enabled: true,
           icon:'fa fa-user',
           selectionMode:'all'
         },
        //  {
        //   id: 2,
        //   text: 'global.menu.fleets',
        //   enabled: true,
        //   icon:'fa fa-truck'
        // },
        // {
        //   id: 3,
        //   text: 'global.menu.teams',
        //   enabled: true,
        //   icon:'fa fa-group'
        // },

      ],
      error: null,
      defaultAssetType:'VEHIC'
    }

export const initialStateUser : CurrentUserInfo = {
  impersonatingOtherUser :false,
  user:null,
  customer: null,
  menu: []

}

export const initialPeriod : SelectedPeriodFitler = {
  fromDtPicker:0,
  toDtPicker:0,
  timespanSel:'',
  isApply:false

}


export const initialCompleteApplyState : CompleteApplyState =
{
  isLoading:false,
  applyCustomer:intialStateCustomerCache,
  applySelectedAssets:initialSelectedAssets,
  applySelectedPeriod:initialPeriod,
  includeChildrens:false,
  error:null

}

export const initialFilterRefSetting: FilterRefSetting =
{
  isSetting:true,
  showFilter:true,
  showDetailAssets:true,
  showIncludeChildrens:false,

}


export const reducersCustomers = createReducer(
  initialState,
  on(PostsActions.getCustomers, (state) => ({ ...state, isLoading: true })),
  on(PostsActions.getSuccesCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);

export const reducersVehiclesOfCustomers = createReducer(
  initialState,
  on(PostsActions.getVehiclesOfCustomers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PostsActions.getSuccesVehiclesOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureVehiclesOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);

export const reducerTrailersOfCustomers = createReducer(
  initialState,
  on(PostsActions.getTrailersOfCustomers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PostsActions.getSuccesTrailersOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureTrailersOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);


export const reducersFleetsOfCustomers = createReducer(
  initialState,
  on(PostsActions.getFleetsOfCustomers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PostsActions.getSuccesFleetsOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureFleetsOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);

export const reducersDriversOfCustomers = createReducer(
  initialState,
  on(PostsActions.getDriversOfCustomers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PostsActions.getSuccesDriversOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureDriversOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);

export const reducersTeamsOfCustomers = createReducer(
  initialState,
  on(PostsActions.getTeamsOfCustomers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PostsActions.getSuccesTeamsOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    posts: action.posts
  })),
  on(PostsActions.getFailureTeamsOfCustomers, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
);

export const reducersSelectedCustomerCache = createReducer(
  intialStateCustomerCache,
  on(PostsActions.setSelectedCustomerCache, (state, action) => ({
    ...state,
    isVirtual: action.isVirtual,
    name: action.name,
    uid: action.uid,
    timezone : action.timezone
  }))
);

export const reducersSetTabFilterRef = createReducer(
  initialListStateTabRefFilter,
  on(PostsActions.setTabFilterRef, (state,action) => ({
    ...state,
    isLoading: true,
    defaultAssetType:action.defaultAssetType,
    tabFilterRef:action.tabFilterRef
  })),

);

export const reducerSetTabAssetChooserRef = createReducer(
  initialListStateTaAssetChooserRefFilter,
  on(PostsActions.setTabAssetChooserRef, (state,action) => ({
    ...state,
    isLoading: true,
    defaultAssetType : action.defaultAssetType,
    tabFilterRef:action.tabFilterRef
  })),

);

export const reducersUser = createReducer(
  initialStateUser,
  on(PostsActions.setUser, (state,action) => ({
    ...state,
    impersonatingOtherUser:action.impersonatingOtherUser,
    user:action.user,
    customer: action.customer,
    menu:action.menu,
  })),

);

export const reducersApplySelectedCustomer = createReducer(
  intialStateCustomerCache,
  on(PostsActions.setApplySelectedCustomer , (state, action) => ({
    ...state,
    state:action,
    isVirtual: action.isVirtual,
    name: action.name,
    uid: action.uid,
    timezone :action.timezone
  }))
);

export const reducersSetSelectedAssets = createReducer(
  initialSelectedAssets,
  on(PostsActions.setSelectedAssets , (state, action) => ({
    ...state,
    assetType: action.assetType,
    selected: action.selected,
    totSelected : action.selected.length,
    isApply:action.isApply
  }))
);


export const reducersApplySelectedAssets = createReducer(
  initialApplySelectedAssets,
  on(PostsActions.setApplySelectedAssets , (state, action) => ({
    ...state,
    assetType: action.assetType,
    selected: action.selected,
    totSelected : action.selected.length,
    isApply:action.isApply
  }))
);


export const reducersSetSelectedPeriod = createReducer(
  initialPeriod,
  on(PostsActions.setSelectedPediod , (state, action) => ({
    ...state,
    fromDtPicker:action.fromDtPicker,
    toDtPicker:action.toDtPicker,
    timespanSel:action.timespanSel

  }))
);

export const reducersApplySelectedPeriod = createReducer(
  initialPeriod,
  on(PostsActions.setApplySelectedPediod , (state, action) => ({
    ...state,
    fromDtPicker:action.fromDtPicker,
    toDtPicker:action.toDtPicker,
    timespanSel:action.timespanSel,
    isApply:true

  }))
);


export const reducersIsApplySelectedAssets = createReducer(
  initialSelectedAssets,
  on(PostsActions.setIsApplySelectedAssets , (state, action) => ({
    ...state,
    isApply:action.isApply
  }))
);

export const reducerCompleteApplyState = createReducer(
  initialCompleteApplyState,
  on(PostsActions.getCompleteApplyState , (state) => ({
    ...state,
    isLoading:true
  })),
  on(PostsActions.setCompleteApplyStateCustomer , (state,action) => ({
    ...state,
    applyCustomer : action,
    isLoading:true
  })),
  on(PostsActions.setCompleteApplyStateSelectedAssets , (state,action) => ({
    ...state,
    applySelectedAssets : action,
    isLoading:true
  })),
  on(PostsActions.setCompleteApplyStateSelectedPeriod , (state,action) => ({
    ...state,
    applySelectedPeriod : action.applySelectedPeriod,
    isLoading:true
  })),
  on(PostsActions.setCompleteApplyStateIncludeChildrens , (state,action) => ({
    ...state,
    includeChildrens : action.includeChildrens,
    isLoading:true
  })),
  on(PostsActions.setCompleteApplyState , (state,action) => ({
    ...state,
    applyCustomer:action.applyCustomer,
    applySelectedAssets:action.applySelectedAssets,
    applySelectedPeriod : action.applySelectedPeriod,
    isLoading:true
  })),

);


export const reducerFilterRefSetting = createReducer(
  initialFilterRefSetting,
  on(PostsActions.setFilterRefSetting , (state, action) => ({
    ...state,
    isSetting:action.isSetting,
    showFilter:action.showFilter,
    showDetailAssets:action.showDetailAssets,
    showIncludeChildrens:action.showIncludeChildrens

  })),
  on(PostsActions.setFilterRefSettingIncludeChildrens , (state, action) => ({
    ...state,
    includeChildrens:action.showIncludeChildrens

  })),


);









