import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// import DevExpress from "devextreme";
import { ALERTS_BY_CUSTOMER_ENDPOINT } from "../config/config.constants";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { DataService } from "./data.service";
import { SignalrService } from "./signalr.service";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: 'root'
})
export class AlertingService {

    constructor(private authService: AuthService, private dataService: DataService, private signalrService: SignalrService, private translateService: TranslateService, private apiService: ApiService, private utilsService: UtilsService) {
        this.identity = this.dataService.getCurrentUser();
        this.hasAlerting = this.authService.isFeatureGranted('alertNotification', 'read') ?? false;
        this.user = this.identity?.user;
    }
    identity: any;
    hasAlerting: boolean;
    user: any;

    alertingSignalRConfig = {
        startNegotiateNamespace: 'tracking/startLiveMap',
        hubNamespace: 'AlertingNotification',
        onmessage: [
            {
                topic: 'PushNotification',
                callback: this.toastCallback.bind(this)
            },
            {
                topic: 'PushUnreadCount',
                callback: this.updateUnreadCount.bind(this)
            }
        ],
        onstart: this.alertingHubOnStart.bind(this),
        ondisconnected: this.alertingHubOnDisconnected.bind(this)
    };

    toasts = [];



    private alertNotReads = 0;

    setAlertNotReads(updatedCount: number) {
        this.alertNotReads = updatedCount;
    }


    getAlertNotReads() {
        return this.alertNotReads;
    }

    alertingConnect() {
        if (this.hasAlerting) {
            if (!this.identity?.user?.isDemo) {
                this.signalrService.connect(this.alertingSignalRConfig);
            }
            else {
                this.generateDemoAlert();
            }
        }
    }

    toastCallback(message) {
        //var jsData = JSON.parse(message);
        this.showToast(message);
    }

    updateUnreadCount(message) {
        this.setAlertNotReads(message.Count);
    }



    alertingHubOnStart() {

        var filters = {
            initPeriod: 0,
            endPeriod: 0,
            assetType: 'USER',
            uidAssets: [this.user.uid],
            uidCustomer: this.user.uidCustomer
        };

        this.signalrService.invoke(this.alertingSignalRConfig.hubNamespace, 'setFilter', filters);

    }

    alertingHubOnDisconnected(e) { }



    showToast(data) {
        var message = this.translateService.instant('global.alertMessageTemplate');
        message = message.replace('{eventType}', data.EventType);
        switch (data.AssetInfo.AssetType) {
            case 'VEHIC':
                message = message.replace('{assetType}', this.translateService.instant('global.vehicle'));
                break;
        }

        message = message.replace('{asset}', data.AssetInfo.InternalID);
        /*   DevExpress.ui.notify({
              uid: data.Id,
              closeOnClick: true,
              closeOnOutsideClick: false,
              message: message,
              type: 'warning',
              position: {
                  collision: 'fit flip',
                  my: 'right bottom', at: 'right bottom', of: window,
                  offset: { x: -20, y: - 20 - (this.toasts.length) * 40 }
              },
              displayTime: 10000,
              maxWidth: 450,
              //width: '100%',
              onHiding: function (e) {
                  //markAlertAsRead(e.component.option('uid'));
                  for (var i = 0; i < this.toasts.length; i++) {
                      if (this.toasts[i].option('uid') === e.component.option('uid')) {
                          this.toasts.splice(i, 1);
                          break;
                      }
                  }
              },
              onHidden: function (e) {
                  //markAlertAsRead(e.component.option('uid'));
                  //for (var i = 0; i < toasts.length; i++) {
                  //    if (toasts[i].option('uid') === e.component.option('uid')) {
                  //        toasts.splice(i, 1);
                  //        break;
                  //    }
                  //}
                  e[0].element.off('click', this.toastOnClick);
              },
              onShowing: function (e) {
                  this.toasts.push(e.component);
                  e.element.on('click', { uid: e.component.option('uid') }, this.toastOnClick);
              }
          }); */
    }

    toastOnClick(e) {
        if (!this.user.isDemo)
            this.markAlertAsRead(e.data.uid);
        else
            this.setAlertNotReads(this.getAlertNotReads() - 1);
    }

    markAlertAsRead(uid) {
        var filters = {
            uidCustomer: this.user.uidCustomer,
            uids: [uid]
        };

        this.apiService.put(ALERTS_BY_CUSTOMER_ENDPOINT + `/${this.user.uidCustomer}` + "/markAlertAsRead", filters.uids)
            .subscribe({
                next: () => {
                    this.signalrService.invoke(this.alertingSignalRConfig.hubNamespace, 'MarkAlertAsRead', {});
                },
                error: (error) => {
                    this.utilsService.showErrorPopup(error);
                }
            });
    }

    generateDemoAlert() {
        var timeoutms = Math.floor(Math.random() * 60 + 1);
        var jsData = {
            Id: 'timeoutms',
            alertSeenByUser: false,
            alertId: this.alertNotReads + 1,
            eventTimestamp: Date.now(),
            EventType: 'GeoFencing',
            eventType: 'GeoFencing',
            assetId: '0XXYY11KABC000_' + timeoutms,
            AssetInfo: { AssetType: 'VEHIC', InternalID: 'INTERNAL_ID_00' + timeoutms },
            AssetType: 'VEHIC',
            internalId: 'INTERNAL_ID_00' + timeoutms,
            ruleName: 'Test'
        };

        setTimeout(() => {
            this.setAlertNotReads(this.getAlertNotReads() + 1);
            this.showToast(jsData);
            this.generateDemoAlert();
        }, timeoutms * 1000);

        /*  $timeout(function () {
             //Generate toast
             ++alertNotReads;

             $sessionStorage.alerts.push(jsData);

             showToast(jsData);

             generateDemoAlert();
         }, timeoutms * 1000); */
    }


}