<div class="card">
  <div class="card-header">
    <div class="circle-icon">
      <i class="fa-solid fa-question iconStyle"></i>
    </div>
    <span class="font-bold white-space-nowrap intestazioni">{{data.title | translate}}</span>
  </div>
  <div class="card-body">
    <p class="card-text textBody">{{data.text | translate}}</p>
  </div>
  <div class="flex justify-end p-2 footerBox">
    <div>
      <button pButton pRipple type="button" icon="pi pi-check" (click)="save()">{{"global.modal.yes" |
        translate}}</button>
    </div>
    <div class="pl-2">
      <button pButton pRipple type="button" icon="pi pi-times" (click)="close()"> {{"global.modal.no" |
        translate}}</button>
    </div>
  </div>
</div>