import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import {
  CURRENT_USER_ENDPOINT,
  DRIVERS_BY_CUSTOMER_ENDPOINT,
  GET_DEVICES_SEARCH,
  GET_DRIVERS_SEARCH,
  GET_VEHICLES_SEARCH,
  MYASSETS_DRIVERS_OFCUSTOMER,
  MYASSETS_FLEETS_OFCUSTOMER,
  MYASSETS_TEAMS_OFCUSTOMER,
  MYASSETS_VEHICLES_OFCUSTOMER
} from 'src/app/core/config/config.constants';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { FilterOptions } from 'src/app/core/models/filterOptions';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as PostActions from '../../../core/store/actions'
import { AppStateInterface } from 'src/app/appState.interface';
import { Store } from '@ngrx/store';
export type AssetsModel = {
  customer: any;
  assets: {
    type: string;
    selected: any[];
  };
};
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  mainColorClass = 'aft-main-bgcolor';
  firstTabOption: string;
  selectedType: any;
  private selectionEmpty: boolean = false;
  mySelectionEmpty = new Subject<boolean>();
  resetCustomer = new Subject<boolean>();
  search = new Subject<boolean>();
  searchText = new Subject<boolean>();
  private activatedRoute: ActivatedRoute


  constructor(
    private utilsService: UtilsService,
    private dataService: DataService,
    public translateService: TranslateService,
    private dialog: MatDialog,
    private angularRouter: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private store:Store<AppStateInterface>) {
    this.mainColorClass = this.utilsService.getStylesheetString() + '-main-bgcolor';
  }

  toggleFilter() {
    let filterBody = document.querySelector('.filter-body') as HTMLElement;
    if (this.isFilterOpen()) {
      this.closeFilter();
    } else {
      this.filterClose = false;
      console.log('------------IL FILTRO è APERTO -------------------')
      filterBody.style.display = 'block';
      filterBody.classList.add('filter-opened');
      //this.initCustomerTree();
      // this.initTimespanChooser();
      this.initTimespanChooserEvent.emit();
      //this.initAssetsChooser().then();
    }
  }

  isFilterOpen(): boolean {
    const filter = document.querySelector('.filter-body') as HTMLElement;
    if (!filter) {
      return false;
    }

    return filter.classList.contains('filter-opened');
  }

  closeFilter() {
    this.selectionEmpty = false;
    this.filterClose = true;
    console.log('------------IL FILTRO è CHIUSO -------------------')
    this.mySelectionEmpty.next(this.selectionEmpty);
    // this.search.next(false);
    this.searchText.next(false);
    (document.querySelector('.filter-body') as HTMLElement).classList.remove(
      'filter-opened'
    );
    let selectedCustomerCache: any = localStorage.getItem('selectedCustomerCache');
    if (selectedCustomerCache && selectedCustomerCache != 'undefined') {
      selectedCustomerCache = JSON.parse(selectedCustomerCache)
      this.setSelectedCustomer(selectedCustomerCache);
    }
    this.resetCustomer.next(true);
  }

  hideFilter() {

    (document.querySelector('.filter-wrapper') as HTMLElement).classList.add(
      'hidden'
    );
  }

  removeHidden() {

    (document.querySelector('.filter-wrapper') as HTMLElement).classList.remove(
      'hidden'
    );
  }

  timespanSelection: any;
  fromDate: any;
  toDate: any;
  userIdentity: any;
  treeElementList: any[] = [];
  listAssets: any[] = [];
  customerSelection: string;

  fromDtPicker: number;
  toDtPicker: number;

  currentSelectedAssets: AssetsModel;
  selectedAssetsChange: any;

  selectedCustomer: any;
  selectedDateCache: any;

  filterOptions: FilterOptions | null;
  filtroApplica: boolean = false;
  filterClose: boolean = true;


  initTimespanChooserEvent = new EventEmitter<any>();
  currentSetEvent = new EventEmitter<any>();
  assetsInitEvent = new EventEmitter<any>();
  assetsTabEvent = new EventEmitter<any>();
  onApplyWithoutSelection = new EventEmitter<any>();
  clearAssetsEvent = new EventEmitter<any>();

  applyFilterEvent = new EventEmitter<any>();
  filterChangeEvent = new EventEmitter<FilterOptions | null>();

  async initAssetsChooser(firstTabOption?: string, isRefreshed?: boolean) {
    let firstTab = firstTabOption ? firstTabOption : 'vehicle';
    this.selectedType = firstTab;
    let currentDataSource: any;

    let newData = await this.getAssetsDataSource(firstTab);
    currentDataSource = {
      customer: this.getSelectedCustomer(),
      assets: {
        type: firstTab,
        selected: newData
      }
    };


    /*if(firstTabOption === this.getSelectedAssets()?.assets?.type && this.getSelectedAssets()?.assets?.selected?.length > 0){
      currentDataSource = this.getSelectedAssets();
    }*/
    /*if (
      !currentDataSource ||
      (currentDataSource && currentDataSource?.assets?.selected.length == 0) ||
      isRefreshed
    ) {
      let newData = await this.getAssetsDataSource(firstTab);
      currentDataSource = {
        customer: this.getSelectedCustomer(),
        assets: {
          type: firstTab,
          selected: newData
        }
      };
    }*/
    ;
    this.selectedAssetsChange = currentDataSource;
    //this.updateSelectedAssets(selectedAssets);
    this.updateSelectedAssetsCache();
    this.assetsInitEvent.emit();
  }

  async initFilter(
    options: FilterOptions | null,
    isRefreshed?: boolean,
    firstTabOption?: string,
    timespanSel?: string
  ) {
    this.search.next(false);
    if (options?.assets?.types[0]) {
      firstTabOption = options?.assets?.types[0];
      localStorage.setItem('selectedTab', firstTabOption);
      let fromCache: any = localStorage.getItem('selectedAssetsCache');
      //localStorage.setItem('selectedAssetsCacheNew', fromCache);
    }
    this.removeHidden();
    if (options?.assets == null && options?.timespan == null && !this.authService.isFeatureGranted('customers', 'read'))
      this.hideFilter();
    this.setFilterOptions(options);
    await this.initCustomerTree();
    this.initTimespanChooser(timespanSel);
    await this.initAssetsChooser(firstTabOption, isRefreshed);
    setTimeout(() => {
      this.filterChangeEvent.emit(options);
    }, 100);


  }

  initTimespanChooser(timespanSel?: string) {

    this.selectedDateCache = localStorage.getItem('selectedDateCache') || {
      timespanSel: 'month',
      fromDtPicker: moment().valueOf(),
      toDtPicker: moment().valueOf()
    };

    if (typeof this.selectedDateCache === 'string') {
      this.selectedDateCache = JSON.parse(this.selectedDateCache);
    }

    var datecached: {
      timespanSel: string;
      fromDtPicker: number;
      toDtPicker: number;
    } = this.selectedDateCache;

    if (timespanSel) {
      datecached.timespanSel = timespanSel;
    }

    this.setTimespanSelection(datecached.timespanSel);
    this.setFromDtPicker(datecached.fromDtPicker);
    this.setToDtPicker(datecached.toDtPicker);

    this.calcToDate(this.getTimespanSelection());
    // this.initTimespanChooserEvent.emit();
  }

  handleCustomerClick(treeView: DxTreeViewComponent) {

    let itemsCollections = treeView.instance
      .element()
      .getElementsByClassName('dx-treeview-item');
    for (let treeItem of Array.from(itemsCollections)) {
      treeItem.classList.remove(this.mainColorClass);
    }
    this.treeElementList.forEach((treeItem) => {
      if (treeItem.itemData.uid === this.getSelectedCustomer()?.uid)
        (treeItem.itemElement as HTMLElement).classList.add(
          this.mainColorClass
        );
    });
    this.clearAssetsEvent.emit(true);
    if (this.currentSelectedAssets) {
      this.currentSelectedAssets.assets.selected = [];
    }
    //if (this.currentSelectedAssets && JSON.stringify(this.currentSelectedAssets)){
    /*localStorage.setItem(
      'selectedAssetsCache',
      JSON.stringify(this.currentSelectedAssets)
    );*/
  }
  //}

  handleTreeExpansionAndCollision(treeView: DxTreeViewComponent) {

    let itemsCollections = treeView.instance
      .element()
      .getElementsByClassName('dx-treeview-node');
    for (let treeItem of Array.from(itemsCollections)) {
      treeItem.classList.remove('dx-state-focused');
    }
  }

  setCustomerTreeItems(elemList) {

    this.treeElementList = elemList;
  }


  async initCustomerTree() {

    let itemFromStorage = localStorage.getItem('selectedCustomerCache');
    if (itemFromStorage != null) {
      itemFromStorage = JSON.parse(itemFromStorage);
      this.setSelectedCustomer(itemFromStorage);
    } else {
      let userInfo = this.dataService.getCurrentUserInfo();
      if (userInfo?.customer) {
        this.setSelectedCustomer(userInfo.customer);
      }
      else {
        const response: any = await this.apiService.get(CURRENT_USER_ENDPOINT).toPromise();
        if (response) {
          this.setSelectedCustomer(response.user.customer);
        }
      }
    }
    this.setCustomerTranslation();
    this.treeElementList.forEach((treeItem) => {
      if (treeItem.itemData.uid === this.getSelectedCustomer()?.uid) {
        this.selectedCustomer.uid = treeItem.itemData.uid;
        (treeItem.itemElement as HTMLElement).classList.add(
          this.mainColorClass
        );
      } else {
        (treeItem.itemElement as HTMLElement).classList.remove(
          this.mainColorClass
        );
      }
    });
  }

  setFilterOptions(filterOpts: FilterOptions | null) {

    this.filterOptions = filterOpts;
  }

  getCurrentFilterOptions() {
    return this.filterOptions;
  }

  setListAssets(list) {
    this.listAssets = list;
  }

  getListAssets() {
    return this.listAssets;
  }


  setCustomerTranslation() {
    this.customerSelection = ': ' + this.getSelectedCustomer()?.name;
  }

  getCustomerTranslation() {
    return this.customerSelection;
  }

  setSelectedCustomer(customer: any) {

    this.selectedCustomer = customer;
    this.setCustomerTranslation();
  }

  getSelectedCustomer() {

    if (this.selectedCustomer) {
      return this.selectedCustomer;
    } else if (localStorage.getItem('selectedCustomerCache')) {
      let customerFromStorage: any = localStorage.getItem('selectedCustomerCache');
      //console.error('customerFromStorage ', customerFromStorage);
      let customerParsed = JSON.parse(customerFromStorage);
      return customerParsed;
    }
  }

  applyFilter(step) {
    switch (step) {
      case 'Customers':
      case 'Clienti':
      case 'Clients':
      case 'Time period':
      case 'Période':
      case 'Periodo':
        this.ricerca();
        break;
      default:
        let select = this.currentSelectedAssets
        if (this.currentSelectedAssets?.assets.selected.length === 0) {
          this.selectionEmpty = true;
          this.mySelectionEmpty.next(this.selectionEmpty);
        } else {
          this.ricerca();
        }
    }
  }
  async ricerca() {
    this.filtroApplica = true;
    this.selectionEmpty = false;
    this.mySelectionEmpty.next(this.selectionEmpty);
    let nome = this.getSelectedCustomer()?.name;
    let selectedCustomerCache: any = localStorage.getItem('selectedCustomerCache');
    selectedCustomerCache = JSON.parse(selectedCustomerCache);
    if (selectedCustomerCache)
    {
      if (selectedCustomerCache.name != null && selectedCustomerCache.name !== nome) {
        this.search.next(false);
      }
    }
    let fromCacheNew: any = localStorage.getItem('selectedAssetsCacheNew');
    fromCacheNew = JSON.parse(fromCacheNew);
    let selectedTab: any = localStorage.getItem('selectedTab');
    if(selectedTab == null){
      localStorage.setItem('selectedTab', 'vehicle');
    }
    if (fromCacheNew) {
      if (fromCacheNew.assets.type != selectedTab) {
        this.firstTabOption = fromCacheNew.assets.type
      } else {
        this.firstTabOption = selectedTab;
      }
    }
    let fromCache: any = localStorage.getItem('selectedAssetsCache');
    if (!fromCache) {
      this.onApplyWithoutSelection.emit();
      if (!this.firstTabOption) { this.firstTabOption = 'vehicle' }
      let newData = await this.getAssetsDataSource(this.firstTabOption);
      let currentDataSource = {
        customer: this.getSelectedCustomer(),
        assets: {
          type: this.firstTabOption,
          selected: newData
        }
      };
      localStorage.setItem('selectedTab', this.firstTabOption);
      let selectedAssets: AssetsModel = currentDataSource;
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(selectedAssets)
      );
      this.updateSelectedAssets(selectedAssets);
      //this.updateSelectedAssetsCache();
    }
    if (
      this.getCurrentFilterOptions()!.assets != null &&
      this.getSelectedAssets()?.assets.selected.length == 0
    ) {
      this.onApplyWithoutSelection.emit();
      let newData = await this.getAssetsDataSource(selectedTab);
      let currentDataSource = {
        customer: this.getSelectedCustomer(),
        assets: {
          type: selectedTab,
          selected: newData
        }
      };
      let selectedAssets: AssetsModel = currentDataSource;
      ;
      this.updateSelectedAssets(selectedAssets);
      this.updateSelectedAssetsCache();
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(selectedAssets)
      );
    } else {
      //this.onApplyWithoutSelection.emit();
      let newData: any;
      let nome = this.getSelectedCustomer()?.name;
      let corrente = this.getSelectedAssets();
      if (corrente.customer) {
        if (nome !== corrente.customer.name) {
          newData = await this.getAssetsDataSource(selectedTab);
        } else {
          newData = this.getSelectedAssets()?.assets.selected;
        }
      }
      let fromCache: any = localStorage.getItem('selectedAssetsCache');
      fromCache = JSON.parse(fromCache);
      let currentDataSource = {
        customer: this.getSelectedCustomer(),
        assets: {
          type: selectedTab,
          selected: newData
        }
      };
      let selectedAssets: AssetsModel = currentDataSource;
      //this.updateSelectedAssets(selectedAssets)
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(selectedAssets)
      );
      localStorage.setItem(
        'selectedAssetsCacheNew',
        JSON.stringify(selectedAssets)
      );
      //this.updateSelectedAssets(selectedAssets);
      //this.updateSelectedAssetsCache();

    }
    this.updateSelectedAssetsCache();
    this.filtroApplica = false;
    this.applyFilterEvent.emit();
    if (!this.filterClose) {
      this.toggleFilter();
    }
  }

  getAssetTypeForAPI(assetType: string) {
    switch (assetType) {
      case 'driver':
        return 'DRIVR';
      case 'teams':
        return 'TEAM';
      case 'fleets':
        return 'FLEET';
      default:
        return 'VEHIC';
    }
  }

  getApiParams() {

    // Pluck uidAssets
    let selectedAssets =
      this.getSelectedAssets() != null
        ? this.getSelectedAssets().assets.selected
        : [];
    let uidAssets: string[] = [];
    if (selectedAssets) {
      selectedAssets.forEach((asset) => {
        uidAssets.push(asset.uid);
      });
    }
    // Get assetType
    let assetType = this.getAssetTypeForAPI(
      this.getSelectedAssets()?.assets?.type
    );
    return {
      uidCustomer: this.getSelectedCustomer()?.uid,
      initPeriod: this.getFromDate(),
      endPeriod: this.getToDate(),
      includeChildrens: localStorage.getItem('includeChildrens'),
      assetType: assetType,
      uidAssets: uidAssets
    };
  }

  updateSelectedAssets(updatedObject: AssetsModel) {
    let assets: any
    this.currentSelectedAssets = updatedObject;
    //console.log('updatedObject : ', updatedObject);
    let fromCacheNew: any = localStorage.getItem('selectedAssetsCacheNew');
    let fromCache: any = localStorage.getItem('selectedAssetsCache');
    if (fromCacheNew == 'undefined' && fromCache == 'undefined') {
      if (this.selectedAssetsChange != undefined) {
        fromCache = this.selectedAssetsChange;
        fromCacheNew = JSON.parse(fromCache);
      } else {
        window.location.href = window.location.origin + '#/app/home'; return
      }
    } else if (fromCache === 'undefined') {
      fromCache = this.selectedAssetsChange;
      fromCacheNew = JSON.parse(fromCache);
    } else {
      fromCacheNew = JSON.parse(fromCacheNew);
      fromCache = JSON.parse(fromCache);
    }
    let selectedTab: any = localStorage.getItem('selectedTab');
    if (!fromCache) {
      this.ricerca();
    }
    if (fromCache && !fromCache.customer) {
      this.ricerca();
    }

    if (updatedObject?.assets?.selected?.length == 0) {
      console.log('No assets found');
      if (this.currentSelectedAssets) {

        if (updatedObject.assets.selected?.length == 0) {
          if (this.selectedType !== fromCache.assets.type) {
            if (this.filtroApplica === true) {
              this.currentSelectedAssets.assets.selected = updatedObject.assets.selected;
              this.filtroApplica = false;
            } else {
              if (this.filterClose) {
                this.currentSelectedAssets = this.selectAll();
              }
            }
            localStorage.setItem(
              'selectedAssetsCacheNew',
              JSON.stringify(this.currentSelectedAssets)
            );
          } else {
            localStorage.setItem(
              'selectedAssetsCacheNew',
              JSON.stringify(fromCache)
            );
          }
        }
      }
    } else if (fromCacheNew && fromCache) {
      if (selectedTab != fromCache.assets.type) {
        if (!this.filtroApplica) {
          if (updatedObject) {
            if (updatedObject.assets.type !== fromCache.assets.type) {
              localStorage.setItem(
                'selectedAssetsCacheNew',
                JSON.stringify(updatedObject)
              );
            } else {
              localStorage.setItem(
                'selectedAssetsCacheNew',
                JSON.stringify(fromCache)
              );
            }
          } else {
            localStorage.setItem(
              'selectedAssetsCacheNew',
              JSON.stringify(fromCache)
            );
          }
          if (this.filterClose) {
            this.currentSelectedAssets = this.selectAll();
            if (this.currentSelectedAssets) {
              localStorage.setItem(
                'selectedAssetsCacheNew',
                JSON.stringify(this.currentSelectedAssets)
              );
            }
          }
        } else {
          this.currentSelectedAssets = this.selectAll();
          localStorage.setItem(
            'selectedAssetsCacheNew',
            JSON.stringify(this.currentSelectedAssets)
          );
        }
      } else {
        this.currentSelectedAssets = updatedObject;
        assets = this.currentSelectedAssets;
        localStorage.setItem(
          'selectedAssetsCacheNew',
          JSON.stringify(assets)
        );
      }
    } else {
      this.currentSelectedAssets = updatedObject;
      assets = this.currentSelectedAssets;
      localStorage.setItem(
        'selectedAssetsCacheNew',
        JSON.stringify(assets)
      );
    }
  }

  getSelectedAssets() {
    let selectedTab: any = localStorage.getItem('selectedTab');
    let fromCache: any = localStorage.getItem('selectedAssetsCache');
    if (fromCache && fromCache == 'undefined') {
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(this.selectedAssetsChange)
      );
      fromCache = this.selectedAssetsChange;
    } else {
      fromCache = JSON.parse(fromCache);
    }
    let fromCacheNew: any = localStorage.getItem('selectedAssetsCacheNew');
    if (fromCacheNew && fromCacheNew != 'undefined') {
      fromCacheNew = JSON.parse(fromCacheNew);
    } else {
      localStorage.setItem(
        'selectedAssetsCacheNew',
        JSON.stringify(fromCache)
      );
      fromCacheNew = fromCache;
    }
    let assets: any;
    if (this.currentSelectedAssets) {
      if (!this.currentSelectedAssets?.assets) {
        assets = { assets: { selected: this.currentSelectedAssets } };
        assets.assets.type = selectedTab;
      } else {
        let nome = this.getSelectedCustomer()?.name;
        if (this.currentSelectedAssets.customer.name === nome && selectedTab === fromCache.assets.type) {
          if (fromCache.assets.selected.every((value, index) => value === fromCacheNew.assets.selected[index])) {
            assets = fromCache;
          } else {
            if (selectedTab === fromCache.assets.type && selectedTab !== fromCacheNew.assets.type) {
              assets = fromCache;
            } else {
              assets = fromCacheNew;
            }
          }
        } else {
          ;
          if (selectedTab === fromCacheNew.assets.type) {
            assets = fromCacheNew;
          } else {
            assets = this.selectedAssetsChange;
          }
        }
      }
    } else if (!fromCache) {
      assets = this.selectedAssetsChange;
      this.updateSelectedAssets(assets);
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(assets)
      );
    }
    else if (selectedTab !== fromCache.assets.type && fromCache.assets.type != null) {
      assets = this.selectedAssetsChange
      this.updateSelectedAssets(assets)
    } else if (fromCache && fromCache !== 'undefined') {
      assets = fromCache;
    }
    return assets;
  }

  selectAll() {
    return this.selectedAssetsChange;
  }

  resetDtPicker() {

    this.setTimespanSelection('month');
    this.setFromDtPicker(moment().valueOf());
    this.setToDtPicker(moment().valueOf());
    this.calcToDate(this.getTimespanSelection());
  }

  async getAssetsDataSource(selectedType: string) {

    let apiResult: any[];
    switch (selectedType) {
      case 'driver':
        apiResult = <any[]>(
          // FZ #6113 elimino autisti con campo alsoDisabled = false - REGRESSIONE - tornati indietro
          await this.apiService.getCachedWithRevalidate(
            MYASSETS_DRIVERS_OFCUSTOMER + this.getSelectedCustomer()?.uid
            //DRIVERS_BY_CUSTOMER_ENDPOINT + '/' + this.getSelectedCustomer().uid + '?alsoDisabled=false'
          )
        );
        break;
      case 'teams':
        apiResult = <any[]>(
          await this.apiService.getCachedWithRevalidate(
            MYASSETS_TEAMS_OFCUSTOMER + this.getSelectedCustomer()?.uid
          )
        );
        break;
      case 'vehicle':
        apiResult = <any[]>(
          await this.apiService.getCachedWithRevalidate(
            MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer()?.uid
          )
        );
        break;
      case 'fleets':
        apiResult = <any[]>(
          await this.apiService.getCachedWithRevalidate(
            MYASSETS_FLEETS_OFCUSTOMER + this.getSelectedCustomer()?.uid
          )
        );
        break;
      default:
        apiResult = <any[]>(
          await this.apiService.getCachedWithRevalidate(
            MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer()?.uid
          )
        );
        break;
    }

    return apiResult;
  }


  async getAssetsDataSourceNoCache(selectedType: string) {
    let apiResult: any[];
    switch (selectedType) {
      case 'driver':
        apiResult = <any[]>(
          await this.apiService
            .get(MYASSETS_DRIVERS_OFCUSTOMER + this.getSelectedCustomer()?.uid)
            .toPromise()
        );
        break;
      case 'teams':
        apiResult = <any[]>(
          await this.apiService.get(
            MYASSETS_TEAMS_OFCUSTOMER + this.getSelectedCustomer()?.uid
          ).toPromise()
        );
        break;
      case 'vehicle':
        apiResult = <any[]>(
          await this.apiService.get(
            MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer()?.uid
          ).toPromise()
        );
        break;
      case 'fleets':
        apiResult = <any[]>(
          await this.apiService.get(
            MYASSETS_FLEETS_OFCUSTOMER + this.getSelectedCustomer()?.uid
          ).toPromise()
        );
        break;
      default:
        apiResult = <any[]>(
          await this.apiService.get(
            MYASSETS_VEHICLES_OFCUSTOMER + this.getSelectedCustomer()?.uid
          ).toPromise()
        );
        break;
    }

    return apiResult;
  }

  calcToDate(currentTimespan) {

    var span: {
      fromDate: number;
      toDate: number;
    };
    switch (currentTimespan) {
      case 'timeperiod':
        span = this.utilsService.calcTimeperiod(
          this.getFromDtPicker(),
          this.getToDtPicker()
        );
        this.setFromDate(span.fromDate);
        this.setToDate(span.toDate);
        break;
      case 'daily':
        span = this.utilsService.calcDaily(this.getFromDtPicker());
        this.setFromDate(span.fromDate);
        this.setToDate(span.toDate);
        break;
      case 'week':
        span = this.utilsService.calcWeek(this.getFromDtPicker());
        this.setFromDate(span.fromDate);
        this.setToDate(span.toDate);
        break;
      case 'last7days':
        span = this.utilsService.calcLast7Days();
        this.setFromDate(span.fromDate);
        this.setToDate(span.toDate);
        break;
      case 'month':
        span = this.utilsService.calcMonth(this.getFromDtPicker());
        this.setFromDate(span.fromDate);
        this.setToDate(span.toDate);
        break;
      default:
        break;
    }
  }

  setTimespanSelection(selection) {
    this.timespanSelection = selection;
  }
  setFromDate(date) {
    this.fromDate = date;
  }
  setToDate(date) {
    this.toDate = date;
  }
  setFromDtPicker(date) {
    this.fromDtPicker = date;
  }
  setToDtPicker(date) {
    this.toDtPicker = date;
  }

  getTimespanSelection() {
    return this.timespanSelection;
  }
  getFromDate() {
    return this.fromDate;
  }
  getToDate() {
    return this.toDate;
  }
  getFromDtPicker() {
    return this.fromDtPicker;
  }
  getToDtPicker() {
    return this.toDtPicker;
  }

  updateSelectedDateCache() {

    var timespanSel = this.getTimespanSelection();
    this.selectedDateCache = {
      timespanSel: timespanSel,
      fromDtPicker: this.fromDtPicker,
      toDtPicker: this.toDtPicker
    };
    localStorage.setItem(
      'selectedDateCache',
      JSON.stringify(this.selectedDateCache)
    );
  }

  updateSelectedCustomerCache() {

    let customer = this.getSelectedCustomer();
    localStorage.setItem('selectedCustomerCache', JSON.stringify(customer));

  }

  updateSelectedAssetsCache() {
    let nome = this.getSelectedCustomer()?.name;
    let selectedAssetsCache = this.getSelectedAssets();
    if (selectedAssetsCache) {

      if (!selectedAssetsCache.customer) {
        this.ricerca();
      } else {
        if (selectedAssetsCache.customer.name !== nome) {
          localStorage.setItem(
            'selectedAssetsCache',
            JSON.stringify(this.getSelectedAssets())
          );
        }
      }

    } else {
      localStorage.setItem(
        'selectedAssetsCache',
        JSON.stringify(this.selectedAssetsChange)
      );
    }

  }

  getSelectedDateCache() {
    return JSON.parse(localStorage.getItem('selectedDateCache')!);
  }

  async searchVehicles(searchPattern) {

    let vehiclesResult = <any[]>(
      await this.apiService
        .get(GET_VEHICLES_SEARCH + '?searchPattern=' + searchPattern)
        .toPromise()
    );
    return vehiclesResult ?? [];
  }

  async searchDrivers(searchPattern) {
    let driversResult = <any[]>(
      await this.apiService
        .get(GET_DRIVERS_SEARCH + '?searchPattern=' + searchPattern)
        .toPromise()
    );
    return driversResult ?? [];
  }

  async searchDevices(searchPattern) {
    let devicesResult = <any[]>(
      await this.apiService
        .get(GET_DEVICES_SEARCH + '?searchPattern=' + searchPattern)
        .toPromise()
    );
    return devicesResult ?? [];
  }
}
