import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ALERTS_WARNING } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-alert-msg',
  templateUrl: './alert-msg.component.html',
  styleUrls: ['./alert-msg.component.scss']
})
export class AlertMsgComponent implements OnInit {
  @Input() namePage: '';
  visible: boolean = false;
  borderColorExpression: boolean = true;

  borderColorInfo: '#099099';
  borderColorWarning: '#f7bd00';
  mgsTextAlert: string = '';

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    let item = localStorage.getItem(`alertMessage-${this.namePage}`);
    let msgAlert = item ? JSON.parse(item) : null;
    let modalPage = {
      namePage: this.namePage
    }
    this.apiService.get(ALERTS_WARNING + `actives?page=${this.namePage}&language=${this.dataService.getLanguage()}`).subscribe({
      next: (res: any) => {
        if (res.length > 0) {
          if (!msgAlert) {
            //this.showDialog();
            localStorage.setItem(`alertMessage-${this.namePage}`, JSON.stringify(modalPage));
            this.mgsTextAlert = res[0].text;
          }
        } else {
          localStorage.removeItem(`alertMessage-${this.namePage}`)
        }
      },
      error: (error) => {

      }
    })
  }

  showDialog() {
    if (!this.borderColorExpression) {
      document.documentElement.style.setProperty('--modal-color-info', '#f7bd00');
      document.documentElement.style.setProperty('--pad-left', '30px')
      document.documentElement.style.setProperty('--pad-left', '16px')
    }
    this.visible = true;
  }

}
