import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WarningModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: string,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
