import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY, map, Observable, ReplaySubject, retryWhen } from 'rxjs';
import { CURRENT_USER_ENDPOINT, ENGINE_TYPES_ENDPOINT, INSTALLATIONS_TYPES_ENDPOINT, MISCELLANEUS_ENDPOINT, VEHICLE_TYPES_ENDPOINT, Vehicules_BY_CUSTOMER_ENDPOINT, VERSION_BAG_ENDPOINT } from 'src/app/core/config/config.constants';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { KeyValueBag } from 'src/app/core/models/keyValueBag';
import { VehicleRestricted } from 'src/app/core/models/vehicleRestricted';
import { ApiService } from 'src/app/core/services/api.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterService } from 'src/app/shared/components/filter/filter.service';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';

import { environment } from 'src/environments/environment';





@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient,
    private apiService: ApiService) {
    //this.initializeStorage();
  }



  baseApi = environment.baseApi;
  baseTraking = environment.baseUrlTruking;
  baseDriverRealTime = environment.baseUrlDriveRealTime;

  private readonly CREDIT_KEY = 'credits';
  private readonly DATE_KEY = 'lastDate';
  private readonly LAST_USAGE_KEY = 'lastUsage';
  private readonly INTERVAL = 5 * 60 * 1000  //60 * 60 * 1000; 
  private readonly MAX_CREDITS = 10;

  private getCurrentDate(): string {
    return new Date().toISOString().split('T')[0];
  }

  private getCurrentTime(): number {
    return new Date().getTime();
  }

  private initializeStorage(): void {
    if (!localStorage.getItem(this.CREDIT_KEY)) {
      localStorage.setItem(this.CREDIT_KEY, this.MAX_CREDITS.toString());
    }
    if (!localStorage.getItem(this.DATE_KEY)) {
      localStorage.setItem(this.DATE_KEY, this.getCurrentDate());
    }
    if (!localStorage.getItem(this.LAST_USAGE_KEY)) {
      localStorage.setItem(this.LAST_USAGE_KEY, '0');
    }
  }

  private formatTime(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  }

  checkAndUseCredit(uidDevice): { success: boolean, reason?: string, timeRemaining?: string } {
    const userData = JSON.parse(localStorage.getItem(uidDevice) || '{}');

    const lastDate = userData[this.DATE_KEY];
    const currentDate = this.getCurrentDate();

    if (lastDate !== currentDate) {
      // Se la data è diversa, resetta i crediti
      userData[this.CREDIT_KEY] = this.MAX_CREDITS.toString();
      userData[this.DATE_KEY] = currentDate;
    }

    const credits = parseInt(userData[this.CREDIT_KEY] || this.MAX_CREDITS.toString(), 10);
    const lastUsage = parseInt(userData[this.LAST_USAGE_KEY] || '0', 10);
    const currentTime = this.getCurrentTime();

    if (credits <= 0) {
      return { success: false, reason: 'no_credits' };
    }

    const timeElapsed = currentTime - lastUsage;
    if (timeElapsed < this.INTERVAL) {
      const timeRemaining = this.INTERVAL - timeElapsed;
      return { success: false, reason: 'interval_not_elapsed', timeRemaining: this.formatTime(timeRemaining) };
    }

    userData[this.CREDIT_KEY] = (credits - 1).toString();
    userData[this.LAST_USAGE_KEY] = currentTime.toString();

    // Salva i dati dell'utente nel localStorage
    localStorage.setItem(uidDevice, JSON.stringify(userData));

    return { success: true };
  }





  postInitialMap$(dataParameters: any): Observable<any> {


    let header: any = this.apiService.isTokenExpiredAndRefresh();
    if (header) {
      return this.http
        .post<any>(this.baseTraking + '/LiveMap/InitialMap', dataParameters, { headers: header })
        .pipe(
          map((Payload) => {
            //console.log(Payload)
            return Payload;
          }),
          catchError((err: any) => {



            return err;
          })
        );
    } else {
      return this.http
        .post<any>(this.baseTraking + '/LiveMap/InitialMap', dataParameters)
        .pipe(
          map((Payload) => {
            //console.log(Payload)
            return Payload;
          }),
          catchError((err: any) => {



            return err;
          })
        );
    }

  }

  postInitialDriverDashboard$(dataParameters: any): Observable<any> {
    let header: any = this.apiService.isTokenExpiredAndRefresh();
    if (header) {
      return this.http
        .post<any>(this.baseDriverRealTime + '/DriverDashboard/InitialDriverDashboard', dataParameters, { headers: header })
        .pipe(
          map((Payload) => {
            //console.log(Payload)
            return Payload;
          }),
          catchError((err: any) => {


            return err;
          })
        );
    }
    else {
      return this.http
        .post<any>(this.baseDriverRealTime + '/DriverDashboard/InitialDriverDashboard', dataParameters)
        .pipe(
          map((Payload) => {
            //console.log(Payload)
            return Payload;
          }),
          catchError((err: any) => {


            return err;
          })
        );
    }
  }






}
