import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem("tokenLoginAs");
    if(token != null)
    {
        const modifiedReq = req.clone({
          headers: req.headers
            .set('Authorization-Impersonate', `Bearer ${token}`)
        });
        return next.handle(modifiedReq).pipe(tap(() => { }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401 && err.status !== 403) {
              return;
            }
            this.authService.logOut();
          }
        }));;
    }
    return next.handle(req).pipe(tap(() => { }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
          return;
        }
          this.authService.logOut();
      }
    }));
  }
}
