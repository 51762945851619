<section [id]="idComponent">
<dx-data-grid
  id="grid"
  [dataSource]="dataTable"
  [keyExpr]="inkeyExpr"
  [columns]="dataCols"
  [showBorders]="showBorders"
  [columnAutoWidth]="true"
  [allowColumnReordering]="allowColumnReordering"
  [allowColumnResizing]="allowColumnResizing"
  [rowAlternationEnabled]="rowAlternationEnabled"
  [showRowLines]="showRowLines"

  [(selectedRowKeys)]="selectedRows"
  (onSelectionChanged) ="eventOnSelectionChanged($event)"
  (onRowUpdated)="eventRowUpdated($event)"
  (onRowRemoving)="onRowRemoving($event)"
  (onSaved)="eventSaved($event)"
  (onEditCanceled)="eventEditCanceled($event)"
  (onEditingStart)="eventEditingStart($event)"


>
<!-- template col -->
<div *dxTemplate="let data of 'dateTemplate'">
  {{ data.value | date:displayFormatDate }}
</div>

<div *dxTemplate="let data of 'dateTimeTemplate'" >
  {{ data.value | date:displayFormatDateTime:userUtc }}
</div>

<div *dxTemplate="let data of 'dateTimeNoSecondTemplate'" >
  {{ data.value * 1000 | date:displayFormatDateTime:userUtc }}
</div>


<!-- template col -->
<div *dxTemplate="let data of 'NATemplate'">
  <div *ngIf=" data.value == 'NA'; else elseBlock">
    <span class="text-orange-600">
      {{ data.value }}
    </span>
    </div>
<ng-template #elseBlock>
  {{ data.value }}
</ng-template>

</div>

<div *dxTemplate="let data of 'dataHiddenTemplate'" cellTemplate="dateTemplate">
  <fa-icon class="ckeck-icon" *ngIf="data.value" [icon]="faCheck"></fa-icon>
</div>

<div *dxTemplate="let data of 'isDoorOpen'" cellTemplate="dateTemplate" alignment="center">
  <ng-container [ngSwitch]="data.value">
    <div *ngSwitchCase="'true'">
      <fa-icon class=" flex justify-center fa-lg  text-success" [icon]="faDoorOpen"></fa-icon>
    </div>
    <div *ngSwitchCase="'false'">
      <fa-icon  class=" flex justify-center fa-lg text-danger"   [icon]="faDoorClose"></fa-icon>
    </div>
    <div *ngSwitchDefault>
      <div class=" flex justify-center fa-lg text-secondary" > - </div>
    </div>
  </ng-container>

</div>

<div *dxTemplate="let data of 'isPowerOn'" cellTemplate="dateTemplate" alignment="center">
  <ng-container [ngSwitch]="data.value">
    <div *ngSwitchCase="'true'">
      <fa-icon class=" flex justify-center fa-lg  text-success" [icon]="faPower"></fa-icon>
    </div>
    <div *ngSwitchCase="'false'">
      <fa-icon  class=" flex justify-center fa-lg text-danger"   [icon]="faPower"></fa-icon>
    </div>
    <div *ngSwitchDefault>
      <div class=" flex justify-center fa-lg text-secondary" > - </div>
    </div>
  </ng-container>
</div>


<div *ngIf="showedit">
  <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
  [useIcons]="true"   [texts]="{confirmDeleteMessage: ''}"  >

</dxo-editing>
   <!-- <dxi-button type="text" name="delete" [onClick]="onDeleteClick"></dxi-button> -->
</div>







<dxo-selection   [selectAllMode]="selectAllMode" [showCheckBoxesMode]="'onClick'" [mode]="selectMode">
</dxo-selection>

<!-- pager setting -->
<dxo-paging [pageSize]="pageSize"> </dxo-paging>
<dxo-pager [allowedPageSizes]="allowedPageSizes"
           [showPageSizeSelector]="showPageSizeSelector"
           [visible]="pagerVisibile"
           [displayMode]="displayMode"
           [showInfo]="showInfo"
           infoText="{{'global.page' | translate}} {0} {{'global.of' | translate}} {1} ({2} {{'global.items' | translate}})"
           [showNavigationButtons]="showNavigationButtons">
</dxo-pager>
<dxo-export [enabled]="enabledExportButton" [allowExportSelectedData]="selectedRows && selectedRows.length > 0"></dxo-export>
<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" [width]="searchWidth" placeholder="{{'global.search' | translate}}">
</dxo-search-panel>

</dx-data-grid>
</section>

