import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subscription
} from 'rxjs';
import { CURRENT_USER_ENDPOINT } from '../config/config.constants';
import { ApiService } from '../services/api.service';
import { mapRealm } from 'src/environments/environment';
import { UtilsService } from '../services/utils.service';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CookiesPopupComponent } from 'src/app/auth/login/cookies-popup/cookies-popup.component';
import { CurrentUserInfo } from '../models/currentUserInfo';
import { DataService } from '../services/data.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard
  extends KeycloakAuthGuard
  implements CanActivate, OnDestroy
{
  apiService: ApiService;
  utilsService: UtilsService;
  idpHint?: String;
  statusChangeSubscription!: Subscription;
  _cookieClicked;
  constructor(
    protected router: Router,
    protected readonly keycloakAngular: KeycloakService,
    apiService: ApiService,
    utilsService: UtilsService,
    private ccService: NgcCookieConsentService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private dataService: DataService
  ) {
    super(router, keycloakAngular);
    this.apiService = apiService;
    this.utilsService = utilsService;
    const realmConfig = mapRealm.get(window.location.hostname);
    this.idpHint = realmConfig?.idpHint;
    let self = this;




    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status == 'dismiss')
          localStorage.setItem('cookieClicked', 'true');
      }
    );
  }
  ngOnDestroy(): void {
    this.statusChangeSubscription?.unsubscribe();
  }

  showPolicy() {
    this.dialog.open(CookiesPopupComponent, {
      width: '520px',
      data: 'confirmDelete'
    });
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      // console.log(
      //   'KeycloakAuthGuard.isAccessAllowed this.authenticated ' +
      //     this.authenticated
      // );
      if (!this.authenticated) {
        const keycloakLoginOptions: Record<string, any> = {};
        if (this.idpHint != null) {
          keycloakLoginOptions.idpHint = this.idpHint.toString();
        }
        this.keycloakAngular.login(keycloakLoginOptions);
        resolve(false);
        return;
      }

      await firstValueFrom(this.apiService.get(CURRENT_USER_ENDPOINT))

        .then((currentUserInfo) => {


          // console.log('currentUserInfo: ' + currentUserInfo);
          // console.log('currentUserInfoJson', JSON.stringify(currentUserInfo))
          if (
            !(currentUserInfo as CurrentUserInfo).user?.preferences &&
            !window.location.hash.endsWith('preferences')
          ) {
            window.location.href = window.location.origin + '#/app/preferences';
          } else {

            this.dataService.setCurrentUserInfo(
              currentUserInfo as CurrentUserInfo
            );
            // #FZ 06112023 ADD HERE SET LANGUAGE, so is set first time for all
            if ((currentUserInfo as CurrentUserInfo).user!.preferences! != null)
            this.dataService.setLanguage((currentUserInfo as CurrentUserInfo).user!.preferences!.language!.label!);
              else
              this.dataService.setLanguage('en');

              this._cookieClicked = localStorage.getItem('cookieClicked');
              if(this._cookieClicked == undefined || this._cookieClicked == 'false'){
                setTimeout(() => {
                  this.ccService.getConfig().content =
                    this.ccService.getConfig().content || {};
                  this.ccService.getConfig().content!.message = this.translate.instant(
                    'global.cookieMessage'
                  );
                  this.ccService.getConfig().content!.dismiss =
                    this.translate.instant('global.dismiss');
                  this.ccService.getConfig().content!.link = this.translate.instant(
                    'global.additionalInformations'
                  );
                  this.ccService.destroy();
                  this.ccService.init(this.ccService.getConfig()); // update config with translated messages
                  document
                    .getElementById('cookieconsent-link')!
                    .addEventListener('click',  () => {
                      this.dialog.open(CookiesPopupComponent, {
                        width: '520px',
                        data: 'confirmDelete'
                      });
                    });
                }, 100);
              }

            resolve(true);
          }
        })
        .catch((error) => {
          console.log('error: ' + error);
          resolve(true);
          this.utilsService.error = error;
          this.router.navigate(['/error']);
        });
    });
  }
}
