<div class="card">
    <h4 class="card-header">{{"global.modal.confirmation" | translate}}</h4>
    <div class="card-body">
      <p class="card-text">{{ data | translate}}</p>
    </div>
    <div class="card-footer">
        <a (click)="save()" class="btn btn-yes">{{"global.modal.yes" | translate}}</a>
        <a (click)="noReply()" class="btn btn-no">{{"global.modal.no" | translate}}</a>
        <a (click)="close()" class="btn btn-cancel">{{"global.modal.cancel" | translate}}</a>
    </div>
</div>