import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { faCoffee, faRefresh, faPlus, faFileExcel, faPen, faBan,faCheckCircle,faEye,faEyeSlash,faArrowRightArrowLeft,faBell,faBellSlash,faBoxArchive, faTrailer, faForward, faBackward, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';





@Component({
  selector: 'actia-bulkFunctionBar',
  templateUrl: './bulkFunctionBar.component.html',
  styleUrls: ['./bulkFunctionBar.component.scss']
})



export class ActiaBulkFunctionBarComponent implements OnInit {
  @Input() idComponent:string ='';
  @Input() bulkBarTitle: string = '';

  @Input() showButtonEdit: boolean = true;
  @Input() showButtonVehicleDisable: boolean = true;
  @Input() showButtonHideShowData: boolean = true;
  @Input() showButtonChangeCustomer: boolean = true;
  @Input() showButtonCanActivatingDevice: boolean = true;
  @Input() showButtonForceDownloadDDD: boolean = true;
  // @Input() showButtonPair:boolean = false;
  @Input() showButtonBack:boolean = false;
  @Input() showButtonSave:boolean = false;





  @Input() tootipEdit: string = '';
  @Input() tootipVehicleDisable: string = '';
  @Input() tooltipHideShowData : string = '';
  @Input() tooltipChangeCustomer : string = '';
  @Input() tooltipCanActivateDevice : string = '';
  @Input() tooltipForceDownloadDDD : string = '';
  // @Input() tooltipPairTrailer : string = '';
  @Input() tooltipBack : string = '';
  @Input() tooltipSave : string = '';




  @Input() faButtonVehicleDisable: any = faCheckCircle;
  @Input() faButtonHideShowData: any = faEye;
  @Input() faButtonCanActivatingDevice: any = faBell;



  @Output() onButtonEditEvent = new EventEmitter()
  @Output() onButtonToggleVehicleDisable = new EventEmitter()
  @Output() onButtonToggleVehicleDataFromReports = new EventEmitter()
  @Output() onButtonOpenChangeCustomer = new EventEmitter()
  @Output() onButtonToggleDeviceSilentMode = new EventEmitter()
  @Output() onButtonForceDownloadDDD = new EventEmitter()
  // @Output() onButtonPairTrailer = new EventEmitter()
  @Output() onButtonBack = new EventEmitter()
  @Output() onButtonSave = new EventEmitter()

  faCoffee = faCoffee;
  faRefresh = faRefresh;
  faFileExcel = faFileExcel;

  faBan = faBan;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCheckCircle =faCheckCircle
  faArrowRightArrowLeft = faArrowRightArrowLeft;
  faBell =faBell
  faBellSlash  = faBellSlash
  faBoxArchive = faBoxArchive;
  // faPairTrailer = faTrailer;
  faBack = faBackward;
  faSave = faFloppyDisk;


  faPen = faPen;
  // faRefresh = REFRESH_ICON;
  // faFileExcel = FILE_EXCEL_ICON;
  // faPlus = PLUS_ICON;
  // faCheck = CHECK_ICON;


  constructor() {

  }


  ngOnInit(): void {



  }


  edit() {

    this.onButtonEditEvent.emit();

  }


  toggleVehicleDisable(){
    this.onButtonToggleVehicleDisable.emit();

  }

  toggleVehicleDataFromReports(){
    this.onButtonToggleVehicleDataFromReports.emit();

  }

  openChangeCustomer(){
    this.onButtonOpenChangeCustomer.emit();

  }

  toggleDeviceSilentMode(){
    this.onButtonToggleDeviceSilentMode.emit();

  }

  forceDownloadDDD(){
    this.onButtonForceDownloadDDD.emit();

  }


  backButton(){
    this.onButtonBack.emit();
  }

  saveButton(){
    this.onButtonSave.emit();
  }
}




