<dx-scroll-view #scrollviewPairTrailers id="scrollviewPairTrailers" [scrollByContent]="scrollByContent"
  [scrollByThumb]="scrollByThumb" [showScrollbar]="scrollbarMode" [bounceEnabled]="pullDown"
  reachBottomText="Updating..." [width]="windowW" >
  <div id="scrollviewPairTrailers-content">

    <div class="card">

        <div class="flex justify-between  card-header ml-2">
          <div>
            <h4 class="m-0"> {{'trailerPage.doPairing' | translate }}</h4>
          </div>
          <div >
              <a (click)="closeDialog()" class="btnClosePair txt-black">
                <fa-icon [icon]="faClose" class="cursor-pointer txt-black"></fa-icon>
              </a>
           </div>
        </div>



      <div class="card-body pt-2">

        <div class="flex flex-row gap-2">
          <div class="basis-1/4" #trailersCol>
            <th class="flex justify-center">
              {{'trailerPage.trailers' | translate }}
            </th>
            <div class="pb-2 h-10 pt-2" *ngIf="listVehicleIn.length >0">
              <dx-list [dataSource]="singleTrailers" height="100%" #listSingleTrailers

              class="list-trailers"
              [noDataText]="notextData"
              >

                <div *dxTemplate="let item of 'item';let i = index;" class="pt-2">
                  <div class="flex items-center">
                    <div class="flex-none ">
                      <i class="flex-none fa fa-arrow-down pr-2" (click)="onCancelSingleTrailerSelect(item)"></i>

                    </div>
                    <div class="flex-auto ">
                      <div class=" item-list-trailers-{{i}}">

                        <div class="flex flex-1 w-64justify-center border-solid border-1 border-neutral-400 p-1 mt-1">{{ item.plate }}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </dx-list>



              <!-- <p-skeleton width="100%" height="2rem" shape="rectangle"></p-skeleton> -->
            </div>
            <hr class="m-1" />
            <dx-list [dataSource]="listTrailerIn" height="100%" #listTrailers
            [searchEnabled]="true"
            searchExpr="plate"
            searchMode="contains"
            (onItemClick)= "listTrailersColSelectionChanged($event)"
            class="list-trailers"
            [noDataText]="notextData"
            >



              <div *dxTemplate="let item of 'item';let i = index;" >

                <div class="flex items-center">
                  <div class="flex-none " *ngIf="listVehicleIn.length >0">
                    <i class="flex-none fa fa-arrow-up pr-2" (click)="onTrailerSelect(item)"></i>


                  </div>
                  <div class="flex-auto ">
                    <div class=" item-list-trailers-{{i}}">

                      <div class="flex flex-1 justify-center border-solid border-1 border-neutral-400 p-1 mb-1 h-7">{{ item.plate }}
                      </div>

                    </div>
                  </div>

                </div>



              </div>
            </dx-list>
          </div>
          <div class="basis-1/4" #vehiclesCol>
            <th class="flex justify-center">
              {{"global.vehicleTypes.truck" | translate}}
            </th>
            <div class="p-inputgroup pb-2 h-10 pt-2" *ngIf="listVehicleIn.length >0">
              <input type="text" pInputText placeholder="Tag" class="p-inputtext-sm"  #singleInputTag [(ngModel)]="singleTag" maxlength="20" >
              <button type="button" pButton pRipple icon="pi pi-angle-double-right" class="p-button-sm p-button-success" (click) = "pairSingleTrailerAndTag()"></button>

            </div>
            <hr class="m-1" />
            <dx-list [dataSource]="listVehicleIn" height="100%" #listVehicles *ngIf="listVehicleIn.length >0"
            [searchEnabled]="true"
            searchExpr="plate"
            searchMode="contains"
            (onItemClick)="listlistVehiclesColSelectionChanged($event)"
            [noDataText]="notextData"

            class="list-vehicles">
              <div *dxTemplate="let item of 'item';let i = index;">
                <div class="item-list-vehicles-{{i}}">

                  <div class="flex justify-center border-solid border-1 border-neutral-400 p-1 mb-1 h-7">{{ item.plate }}</div>

                </div>
              </div>
            </dx-list>



            <dx-list [dataSource]="listTag" height="100%" #listTagInsert *ngIf="listVehicleIn.length == 0"
              [searchEnabled]="true"
              [noDataText]="notextData"

              class="list-vehicles">
                <div *dxTemplate="let item of 'item';let i = index;">
                  <div class="item-list-tag-{{i}}">
                    <div class="p-inputgroup  pb-0.5 h-8">
                    <input type="text" pInputText placeholder="Tag" class="p-inputtext-sm"  id="tag-{{i}}" >
                    <button type="button" pButton pRipple icon="pi pi-angle-double-right" id="btn-tag-{{i}}"class="p-button-sm p-button-success" (click) = "pairListTagAndTrailer(i)"></button>
                      </div>
                  </div>
                </div>
              </dx-list>



          </div>
          <div class="split-layout__divider" *ngIf="listPairsNew &&  listPairsNew.length > 0">
            <div class="flex-1  border-solid border-r-0 border-neutral-400"></div>
            <div class="split-layout__label"><i class="fa-solid fa-repeat" (click) ="cancelPairs()" ></i></div>
            <div class="flex-1 border-solid border-r-0 border-neutral-400"></div>
          </div>
          <div class="basis-1/2" #gridCols >
            <th class="flex justify-center pb-2">
               {{'trailerPage.pairsList' | translate }}
            </th>

            <div class="flex justify-center ">
              <div class="btn-savePairs">
                <a (click)="savePairs()" class="btn btn-default btn-outline-dark btn ml-1"
                pTooltip="{{'global.save' | translate }}" tooltipPosition="left"
                *ngIf="listPairsNew &&  listPairsNew.length > 0 || listPairsIn && listPairsIn.length > 0">
                <fa-icon  [ngClass]="'fa-lg'" [icon]="faSave" class="text-yellow-600"  ></fa-icon>
              </a>
              </div>
              <div class="btn-cancelPairs"> <a (click)="cancelPairs()" class="btn btn-default btn-outline-dark btn ml-1"
                pTooltip="{{'global.cancel' | translate }}" tooltipPosition="right"
                 *ngIf="listPairsNew &&  listPairsNew.length > 0">
                <fa-icon  [ngClass]="'fa-lg'" [icon]="faCancel" class="text-red-600"  ></fa-icon>
              </a></div>

            </div>
            <hr *ngIf="listPairsNew &&  listPairsNew.length > 0"/>

            <div  *ngFor="let pairObject of listPairsNew; let i = index">
              <div class="flex flex-row pair-list-item-{{i}}">
                <div class="basis-11/12">
                  <div class="flex  justify-between basis-3/4 border-dotted border-1 border-neutral-400 mb-1" [ngClass]="{ 'first-row ' : i===0}" @fadeIn>
                    <div class="flex items-center basis-1/2">
                      <i class="fa-solid fa-trailer ml-2"></i>
                      <span class="ml-2"> {{pairObject.trailer.plate}}</span>
                    </div>
                    <div class="flex items-center basis-1/2">
                      <i class="fa-solid fa-truck-arrow-right m-2"></i>
                      <span class="m-2" *ngIf="pairObject.vehicle"> {{pairObject.vehicle.plate}}</span>
                      <span class="m-2" *ngIf="pairObject.tag"> {{pairObject.tag.tag}}</span>
                    </div>
                  </div>

                </div>
                <div class="flex justify-center items-center basis-1/12 btnUnPair-{{i}}">

                    <i class="fa-solid fa-backward" (click)="unPairsRow(pairObject)"></i>



                </div>

              </div>
            </div>



            <!-- <div  *ngFor="let pairObject of listPairsNew; let i = index">
              <div class="flex justify-between items-center">
                <div class="flex  justify-between basis-3/4 border-dotted border-1 border-neutral-400">

                  <div class="flex justify-between  ">
                    <div>
                      <i class="fa-solid fa-trailer ml-2"></i>
                      <span class="ml-2"> {{pairObject.trailer.plate}}</span>
                    </div>


                  </div>




                    <div class="flex flex-row basis-1/4">
                      <i class="fa-solid fa-truck-arrow-right m-2"></i>
                      <span class="m-2"> {{pairObject.vehicle.plate}}</span>
                    </div>





              </div>

              <div class="flex justify-center items-center">
                <i class="fa-solid fa-backward" (click)="unPairsRow(pairObject)"></i>
              </div>
              </div>





            </div> -->










          </div>
        </div>





        </div>


    </div>
  </div>
</dx-scroll-view>
