import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxCheckBoxModule,  DxDataGridModule, DxFormModule, DxListModule, DxButtonModule, DxContextMenuModule, DxMenuModule, DxPopoverModule, DxRadioGroupModule, DxSelectBoxModule, DxTemplateModule, DxTextBoxModule, DxValidatorModule, DxSliderModule, DxTagBoxModule, DxNumberBoxModule, DxValidationGroupModule, DxPopupModule, DxTreeViewModule, DxDateBoxModule, DxTextAreaModule, DxSwitchModule, DxTabsModule, DxFileUploaderModule, DxTabPanelModule, DxAccordionModule, DxRangeSelectorModule, DxColorBoxModule, DxProgressBarModule, DxChartModule, DxScrollViewModule, DxToastModule } from 'devextreme-angular';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidenavComponent } from './components/nav/sidenav/sidenav.component';
import { SidenavItemComponent } from './components/nav/sidenav-item/sidenav-item.component';
import {MatMenuModule} from '@angular/material/menu';
import { HeaderComponent } from './components/nav/header/header.component';
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { RouterModule } from '@angular/router';
import { DxoPagerModule, DxoPagingModule } from 'devextreme-angular/ui/nested';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { CutomersTreeComponent } from './components/cutomers-tree/cutomers-tree.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FilterComponent } from './components/filter/filter.component';

import { AssetsChooserComponent } from './components/assets-chooser/assets-chooser.component';
import { TimespanChooserComponent } from './components/timespan-chooser/timespan-chooser.component';
import { NgWizardModule, THEME } from 'ng-wizard';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AssetsSearchComponent } from './components/assets-search/assets-search.component';
import { HelpHeaderPopupComponent } from './components/page-header/help-header-popup/help-header-popup.component';
import { ActivitiesTileComponent } from './components/activities-tile/activities-tile.component';
import { ComboDriversComponent } from './components/combo-drivers/combo-drivers.component';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GridToolbarComponent } from './components/grid-toolbar/grid-toolbar.component';
import { MiscellaneousModalComponent } from './components/miscellaneous-modal/miscellaneous-modal.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ActiaTableDevExtremeComponent } from './components/tableDevExtreme/tableDevExtreme.component';
import { ActiaTableEditDevExtremeComponent } from './components/tableEditDevExtreme/tableEditDevExtreme.component';
import { ActiaJsonEditorComponent } from './components/jsonEditor/jsonEditor.component';
import { CountDownComponent } from './components/count-down/count-down.component';
import { ModalPopup } from '../pages/tachograph/modal-popup/modal-popup.component';
import { ActiaPanelDetailRowComponent } from './components/panelDetailRow/panelDetailRow.component';
import { ActiaTableFunctionBarComponent } from './components/tableFunctionBar/tableFunctionBar.component';
import { ActiaBulkFunctionBarComponent } from './components/bulkFunctionBar/bulkFunctionBar.component';
import { PairsTrailersModalComponent } from './components/pairs-trailers-modal/pairs-trailers-modal.component';
import { FilterRefComponent } from './components/filter-ref/filter-ref.component';

import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TabViewModule} from 'primeng/tabview';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import { TooltipModule } from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {SkeletonModule} from 'primeng/skeleton';
import {AutoFocusModule} from 'primeng/autofocus';

// import { CutomersTreeRefComponent } from './components/cutomers-tree-ref/cutomers-tree-ref.component';

import { AssetsChooserRefComponent } from './components/assets-chooser-ref/assets-chooser-ref.component';

import { TimespanChooserRefComponent } from './components/timespan-chooser-ref/timespan-chooser-refcomponent';
import { RepliceConfirmationModalComponent } from './components/replice-confirmation-modal/replice-confirmation-modal.component';
import { CutomersTreeRefComponent } from './components/cutomers-tree-ref/cutomers-tree-ref.component';
import { TrackingDirective } from '../core/directives/tracking.directive';
import { AlertMsgComponent } from './components/alert-msg/alert-msg.component';
import { DialogModule } from 'primeng/dialog';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalMsgComponent } from './components/modal-msg/modal-msg.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalWarningComponent } from './components/modal-warning/modal-warning.component';

const COMPONENTS : any[] = [
  HeaderComponent,
  SidenavComponent,
  SidenavItemComponent,
  ConfirmationModalComponent,
  RepliceConfirmationModalComponent,
  ComboDriversComponent,
  ActivitiesTileComponent,
  GridToolbarComponent,
  WarningModalComponent,
  ErrorModalComponent,
  CutomersTreeComponent,
  CutomersTreeRefComponent,
  LanguageSelectorComponent,
  SuccessModalComponent,
  MiscellaneousModalComponent,
  AlertMsgComponent,
  ModalMsgComponent,
  LoadingComponent,
  ActiaTableDevExtremeComponent,
  ActiaTableEditDevExtremeComponent,
  ActiaJsonEditorComponent,
  ActiaPanelDetailRowComponent,
  ActiaTableFunctionBarComponent,
  ActiaBulkFunctionBarComponent,
  PairsTrailersModalComponent,
  CountDownComponent,
  FilterComponent,
  AssetsChooserComponent,
  AssetsChooserRefComponent,
  TimespanChooserComponent,
  TimespanChooserRefComponent,
  PageHeaderComponent,
  AssetsSearchComponent,
  ProgressModalComponent,
  ModalConfirmationComponent,
  ModalWarningComponent

];
const PIPES: any[] = [];
const MODULES: any[] = [
  RouterModule,

  MatSlideToggleModule,

  DxMenuModule,
  DxSwitchModule,
  DxContextMenuModule,
  ClipboardModule,
  HttpClientJsonpModule,
  MatExpansionModule,
  MatProgressBarModule,
  DxRadioGroupModule,
  DxTabsModule,
  DxPopoverModule,
  DxTextBoxModule,
  DxButtonModule,
  DxValidatorModule,
  DxChartModule,
  DxoPagerModule,
  DxTextAreaModule,
  DxTabPanelModule,
  DxoPagingModule,
  DxFileUploaderModule,
  DxListModule,
  DxTagBoxModule,
  DxDataGridModule,
  DxColorBoxModule,
  DxTextAreaModule,
  MatSidenavModule,
  DxFormModule,
  DxProgressBarModule,
  DxTextBoxModule,
  DxRangeSelectorModule,
  DxCheckBoxModule,
  DxAccordionModule,
  DxTagBoxModule,
  DxValidationGroupModule,
  DxButtonModule,
  FormsModule,
  DxNumberBoxModule,
  ReactiveFormsModule,
  DxTreeViewModule,
  DxDateBoxModule,
  DxSelectBoxModule,
  DxPopupModule,
  DxPopoverModule,
  DxSliderModule,
  MatMenuModule,
  RouterModule,
  MatDialogModule,
  DxTabPanelModule,
  FontAwesomeModule,
  DxTemplateModule,
  DxScrollViewModule,
  NgJsonEditorModule,
  DxToastModule,
  OverlayPanelModule,
  TabViewModule,
  DividerModule,
  FieldsetModule,
  TooltipModule,
  ProgressBarModule,
  InputTextModule,
  ButtonModule,
  SkeletonModule,
  AutoFocusModule,

  TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
  }),
  MatPasswordStrengthModule.forRoot(),
  NgWizardModule.forRoot({
    theme: THEME.default
  })
];
const DIRECTIVES: any[] = [];


@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES, PageHeaderComponent, HelpHeaderPopupComponent, ModalPopup, FilterRefComponent,TrackingDirective],
  exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES, ...MODULES],
  imports: [
    CommonModule,
    DialogModule,
     //StoreModule.forFeature('getCustomers',reducersCustomers),
     //StoreModule.forFeature('getVehiclesOfCustomers',reducersVehiclesOfCustomers),
     //StoreModule.forFeature('getFleetsOfCustomers',reducersFleetsOfCustomers),
     //StoreModule.forFeature('getDriversOfCustomers',reducersDriversOfCustomers),
     //StoreModule.forFeature('getTeamsOfCustomers',reducersTeamsOfCustomers),
    // StoreModule.forFeature('setSelectedCustomerCache',reducersSelectedCustomerCache),
     //StoreModule.forFeature('setTabFilterRef',reducersSetTabFilterRef),
     //StoreModule.forFeature('setTabAssetChooserRef',reducerSetTabAssetChooserRef),
     //StoreModule.forFeature('user',reducersUser),
    // StoreModule.forFeature('applySelectedCustomer',reducersApplySelectedCustomer),
    // StoreModule.forFeature('setSelectedAssets',reducersSetSelectedAssets),
   //  StoreModule.forFeature('applySelectedAssets',reducersApplySelectedAssets),

    // //StoreModule.forFeature('posts',reducers),
    // EffectsModule.forFeature([PostEffects,HydrationEffects]),


    ...MODULES
  ]
})
export class SharedModule {

}


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
